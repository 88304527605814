import React, { useEffect, useState, useContext } from "react";
import { useFood } from "../../context/FoodContext";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./AddFoodItem.css";
import axios from "axios";
import ParseMenu from "../ParseMenu/ParseMenu";

const AddFoodItem = ({ editMode }) => {
  const { foodID } = useParams();
  const { user } = useContext(AuthContext);
  const {
    createFoodItem,
    updateFoodItem,
    fetchAllFoodItems,
    fetchRestaurants,
  } = useFood();
  const [foodData, setFoodData] = useState({
    foodID: "",
    name: "",
    category: "",
    ingredients: "",
    price: "",
    size: "",
    serves: "",
    restaurantID: "",
    userID: "",
  });
  const [restaurants, setRestaurants] = useState([]);
  const [isParseModalOpen, setIsParseModalOpen] = useState(false);

  useEffect(() => {
    if (user && user.userID) {
      const fetchRestaurantsData = async () => {
        const restaurantNames = await fetchRestaurants(user.userID);
        setRestaurants(restaurantNames);
      };
      fetchRestaurantsData();
    }
  }, [user]);

  useEffect(() => {
    if (editMode && foodID) {
      const fetchFoodData = async () => {
        try {
          const response = await axios.get(`/api/food-items/${foodID}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          if (response.status === 200) {
            setFoodData(response.data);
          } else {
            throw new Error("Failed to fetch food data");
          }
        } catch (error) {
          alert("Failed to fetch food data. Please try again.");
        }
      };
      fetchFoodData();
    }
  }, [editMode, foodID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFoodData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const foodItemData = {
        ...foodData,
        restaurantID: foodData.restaurantID,
        userID: user.userID,
      };
      if (editMode) {
        await updateFoodItem(foodID, foodItemData);
        alert("Food item updated successfully!");
      } else if (user.userID) {
        await createFoodItem(foodItemData);
        alert("Food item added successfully!");
      }
      fetchAllFoodItems({ userID: user.userID });
    } catch (error) {
      alert("Failed to save food item. Please try again.");
    }
  };

  // Callback for parsed menu submission from the modal
  const handleParsedMenuSubmit = async (menuItems, restaurantID) => {
    // Loop through each parsed menu item and create it via the API.
    for (let item of menuItems) {
      const foodItemData = {
        ...item,
        restaurantID: restaurantID,
        userID: user.userID,
      };
      try {
        await createFoodItem(foodItemData);
      } catch (error) {
        console.error("Error adding food item:", error);
      }
    }
    fetchAllFoodItems({ userID: user.userID });
  };

  return (
    <div className="add-food-item-container">
      <h2 className="form-title">
        {editMode ? "Edit Food Item" : "Add New Food Item"}
      </h2>
      {/* New "Upload Menu" button */}
      <div className="upload-menu">
        <button
          className="upload-menu-btn"
          onClick={() => setIsParseModalOpen(true)}
        >
          Upload Menu
        </button>
        <ParseMenu
          isOpen={isParseModalOpen}
          onClose={() => setIsParseModalOpen(false)}
          restaurantOptions={restaurants}
          onParsedMenuSubmit={handleParsedMenuSubmit}
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={foodData.name}
              onChange={handleChange}
              placeholder="Food Name"
              required
            />
          </div>
          <div className="form-group">
            <label>Category</label>
            <input
              type="text"
              name="category"
              value={foodData.category}
              onChange={handleChange}
              placeholder="Category"
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Price</label>
            <input
              type="number"
              name="price"
              value={foodData.price}
              onChange={handleChange}
              placeholder="Price"
              required
            />
          </div>
          <div className="form-group">
            <label>Size</label>
            <input
              type="text"
              name="size"
              value={foodData.size}
              onChange={handleChange}
              placeholder="Size"
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Serves</label>
            <input
              type="number"
              name="serves"
              value={foodData.serves}
              onChange={handleChange}
              placeholder="Serves"
              required
            />
          </div>
          <div className="form-group">
            <label>Restaurant</label>
            <select
              name="restaurantID"
              value={foodData.restaurantID}
              onChange={handleChange}
              required
            >
              <option value="">Select Restaurant</option>
              {restaurants.map((restaurantName, index) => (
                <option key={index} value={restaurantName}>
                  {restaurantName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label>Ingredients</label>
          <textarea
            name="ingredients"
            value={foodData.ingredients}
            onChange={handleChange}
            placeholder="Ingredients"
            required
          />
        </div>
        <button type="submit" className="submit-btn">
          {editMode ? "Update" : "Add"}
        </button>
      </form>
    </div>
  );
};

export default AddFoodItem;
