import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { KitchenProvider } from "../../context/KitchenContext";
import { AuthContext } from "../../context/AuthContext";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import UserMenuTabs from "../../components/UserMenuTabs/UserMenuTabs";
import ProfileDetails from "../../components/ProfileDetails/ProfileDetails";
import AddKitchen from "../../components/AddKitchen/AddKitchen";
import MyKitchens from "../../components/MyKitchens/MyKitchens";
import MyBookings from "../../components/MyBookings/MyBookings";
import MyReservations from "../../components/MyReservations/MyReservations";
import Calendar from "../../components/Calendar/Calendar";
import Invoices from "../../components/Invoices/Invoices";
import Settings from "../../components/Settings/Settings";
import "./Profile.css";
import Messages from "../../components/Messages/Message";
import AddRestaurant from "../../components/AddRestaurant/AddRestaurant";
import MyRestaurants from "../../components/MyRestaurants/MyRestaurants";
import AddFoodItem from "../../components/AddFoodItem/AddFoodItem";
import MyFoodItems from "../../components/MyFoodItems/MyFoodItems";
import { FoodProvider } from "../../context/FoodContext";

const Profile = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="profile-page">
      <Navbar />
      <div className="profile-content">
        <div className="sidebar">
          {/* Only render UserMenuTabs if the user is logged in */}
          {user && <UserMenuTabs />}
        </div>
        <div className="main-content">
          <KitchenProvider>
            <FoodProvider>
              <Routes>
                <Route path="/" element={<ProfileDetails />} />
                <Route path="messages" element={<Messages />} />
                <Route
                  path="add-kitchen"
                  element={<AddKitchen editMode={false} />}
                />
                <Route
                  path="edit-kitchen/:kitchenID"
                  element={<AddKitchen editMode={true} />}
                />
                <Route
                  path="add-food-item"
                  element={<AddFoodItem editMode={false} />}
                />
                <Route
                  path="edit-food-item/:foodID"
                  element={<AddFoodItem editMode={true} />}
                />
                <Route
                  path="add-restaurant"
                  element={<AddRestaurant editMode={false} />}
                />
                <Route
                  path="edit-restaurant/:restaurantID"
                  element={<AddRestaurant editMode={true} />}
                />
                <Route path="my-kitchens" element={<MyKitchens />} />
                <Route path="my-bookings" element={<MyBookings />} />
                <Route path="my-restaurants" element={<MyRestaurants />} />
                <Route path="my-food-item" element={<MyFoodItems />} />
                <Route path="my-reservations" element={<MyReservations />} />
                <Route path="calendar" element={<Calendar />} />
                <Route path="invoices" element={<Invoices />} />
                <Route path="settings" element={<Settings />} />
              </Routes>
            </FoodProvider>
          </KitchenProvider>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
