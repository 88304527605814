import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./AboutUs.css";

const teamMembers = [
  {
    name: "Diego Leporini",
    role: "Co-Founder and CEO",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/DiegoMTT.jpg",
  },
  {
    name: "Kunal Doshi",
    role: "Co-Founder & Developer",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/KunalMTT.jpeg",
  },
  {
    name: "Vaibhav Monpara",
    role: "Co-Founder & Developer",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/VickMTT.jpg",
  },
  {
    name: "Vasu Lakhani",
    role: "Software Engineer",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/VasuMTT.jpg",
  },
  {
    name: "Yash Gandhi",
    role: "Software Engineer",
    image:
      "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/profile-pictures/YashMTT.jpg",
  },
];

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <Navbar />

      {/* About Section: Left image, right text */}
      <div className="about-header">
        <div className="aboutus-image-container">
          <img
            src={require("../../assets/images/aboutus-background.jpg")}
            alt="Chef working"
          />
        </div>
        <div className="text-container">
          <div className="section-header">
            <h2>AirKitchenz</h2>
          </div>

          <p>
            "The Airbnb for Restaurant Kitchens," is The First Marketplace
            connecting underutilized restaurant kitchens with food
            entrepreneurs. Restaurants earn passive income by renting spaces
            during off-hours, while businesses launch and grow without costly
            build-outs—redefining the future of food businesses
          </p>
        </div>
      </div>

      {/* Story Section */}
      <div className="story-section">
        <div className="story-content">
          <div className="section-header">
            <h2>The Story of AirKitchenz: How It All Started</h2>
          </div>

          <p>
            In 2017, Diego Leporini found himself at rock bottom. After shutting
            down his last brick-and-mortar restaurant, he was left with nothing—
            not even a single dollar to his name. With no resources to start
            over, he was forced to get creative. Cooking out of his small
            one-bedroom apartment, he took on catering orders just to survive.
            But Diego knew that if he wanted to build a real business again, he
            needed a professional kitchen.
          </p>
          <p>
            The problem? The costs were overwhelming. Commercial kitchen
            equipment, government regulations, build-out expenses—each hurdle
            seemed impossible to overcome. Opening another traditional
            restaurant was simply out of the question.
          </p>
          <p>
            Determined to find another way, Diego took an unconventional
            approach. He went door to door, restaurant to restaurant, asking if
            he could rent their kitchen space during off-hours. To his surprise,
            eight out of ten restaurants said yes. These owners were struggling
            too, and the extra income from renting their kitchens was an
            opportunity they hadn’t considered before. That was when the idea
            first took shape—though at the time, Diego didn’t realize just how
            transformative it could be.
          </p>
          <p>
            Using this simple but effective model, he managed to operate
            multiple catering locations without the crushing financial burden of
            opening new spaces. But Diego didn’t stop there.
          </p>
          <p>
            Before anyone was talking about ghost kitchens or virtual
            restaurants, Diego was already operating them. Since 2017, he had
            been running multiple food concepts—catering businesses, virtual
            restaurants, and delivery-only brands—all from restaurant kitchens
            that were closed during off-hours. He figured out how to maximize
            kitchen space efficiency before the term “ghost kitchen” even became
            mainstream.
          </p>
          <p>
            At the height of this operation, Diego was running six to seven
            different kitchen locations, each hosting multiple brands
            simultaneously. Some kitchens supported five or more food concepts
            at once—serving catering orders, operating delivery-only
            restaurants, and expanding across multiple locations without ever
            having to build out a new space. Long before platforms like
            CloudKitchens or Reef gained traction, Diego was proving that this
            model worked. He just wasn’t broadcasting it—he was too busy scaling
            his business.
          </p>
          <p>
            Then, about a year ago, someone pointed out what Diego had been
            doing all along—it wasn’t just a workaround; it was an entirely new
            business model. That realization changed everything. He saw the
            potential to take what had once been a personal survival strategy
            and turn it into something much bigger.
          </p>
          <p>
            In early 2024, Diego decided to go all in, committing himself 24/7
            to building AirKitchenz, a platform designed to transform the food
            industry.
          </p>
          <ul>
            <li>
              For restaurant owners, AirKitchenz provides a way to generate
              additional revenue by renting out their underutilized kitchen
              space.
            </li>
            <li>
              For food entrepreneurs, it removes the biggest barriers to entry—
              build-out costs, long-term leases, and regulatory headaches—giving
              them the flexibility to test and launch their ideas with minimal
              risk.
            </li>
          </ul>
          <p>
            Diego knew there would be resistance. Some restaurant owners might
            hesitate at the idea of sharing their kitchen space, just as people
            initially doubted Airbnb and Uber. But he firmly believed that, like
            those industry disruptors, AirKitchenz had the power to change the
            game.
          </p>
          <p>
            With a team of dedicated engineers and visionaries working
            tirelessly— many without pay, simply believing in the
            mission—AirKitchenz is set to revolutionize how food businesses
            start and operate. With cutting- edge AI tools on the way, the
            platform will streamline everything from kitchen matchmaking to
            business setup, making it easier than ever for aspiring food
            entrepreneurs to succeed.
          </p>
          <p>
            Today, Diego Leporini is on a mission to change the future of food
            entrepreneurship. AirKitchenz isn’t just a business—it’s a movement.
            It’s about creating opportunities, eliminating unnecessary barriers,
            and empowering the next generation of food businesses.
          </p>
          <p>The revolution has begun. AirKitchenz is coming.</p>
        </div>
      </div>

      {/* Team Section */}
      <div className="team-header">
        <div className="section-header">
          <h2>Meet the Minds Behind AirKitchenz AI</h2>
        </div>

        <p>
          From visionary developers crafting our platform to co-founders driving
          our mission, to marketing specialists spreading the word—our team is
          the force turning kitchens into opportunities.
        </p>
      </div>

      <div className="team-section">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <img src={member.image} alt={member.name} />
            <h4>{member.name}</h4>
            <p>{member.role}</p>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
