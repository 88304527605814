import React, { useRef, useState, useEffect } from "react";
import "./HeroSection.css";
import { Link, useNavigate } from "react-router-dom";
import arrowCircleRight from "../../images/Arrow circle right.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import badgeImage from "./badge.png"; // Import the badge image

// Load Google Maps script
const loadGoogleMapsScript = (callback) => {
  if (!document.querySelector(`[src*="maps.googleapis.com"]`)) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&loading=async`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => callback();
  } else {
    callback();
  }
};

export const HeroSection = () => {
  const locationInputRef = useRef(null);
  const autocompleteRef = useRef(null);
  const [filters, setFilters] = useState({
    location: "",
    latitude: null,
    longitude: null,
    kitchenType: "", // Retained for logic consistency
  });

  const navigate = useNavigate();

  // Initialize Google Maps Autocomplete once
  useEffect(() => {
    const initializeAutocomplete = () => {
      if (locationInputRef.current && window.google && window.google.maps) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          locationInputRef.current,
          { types: ["geocode"] }
        );
        autocomplete.setFields(["geometry", "formatted_address"]);
        autocomplete.addListener("place_changed", handlePlaceSelect);
        autocompleteRef.current = autocomplete;
      }
    };

    loadGoogleMapsScript(() => {
      initializeAutocomplete();
    });

    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(
          autocompleteRef.current
        );
      }
    };
  }, []);

  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        const formattedAddress = place.formatted_address;

        setFilters((prevFilters) => ({
          ...prevFilters,
          location: formattedAddress,
          latitude,
          longitude,
        }));

        // Call handleSearch with the updated latitude and longitude directly
        handleSearch(latitude, longitude);
      } else {
        console.log("No place selected or invalid place data.");
      }
    }
  };

  const handleSearch = (latitude, longitude) => {
    const queryParams = new URLSearchParams();
    queryParams.append("latitude", latitude);
    queryParams.append("longitude", longitude);

    navigate(`/browse?${queryParams.toString()}`);
  };

  // Handler for pressing Enter key in location input
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // Trigger place_changed to finalize the selection if Enter is pressed
      if (autocompleteRef.current) {
        handlePlaceSelect();
      }
    }
  };

  const handleLocationChange = (e) => {
    setFilters({
      ...filters,
      location: e.target.value,
      latitude: null,
      longitude: null,
    });
  };

  return (
    <div className="main-section-search">
      <a
        href="https://www.f6s.com/airkitchenz-ai"
        target="_blank"
        rel="noopener noreferrer"
        className="badge-link"
      >
        <img
          src={badgeImage}
          alt="Top AI Infrastructure Startups Badge"
          className="badge-image"
        />
      </a>
      <h1 className="title">Find Your Perfect Kitchen</h1>
      <p className="subtitle">
        AirKitchenz transforms underutilized restaurant kitchens into rentable
        spaces
      </p>
      <div className="home-filters">
        <div className="input-group">
          <FontAwesomeIcon icon={faSearch} className="input-icon" />
          <input
            type="text"
            ref={locationInputRef}
            placeholder="Search for kitchen location"
            className="input-field"
            value={filters.location}
            onChange={handleLocationChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>

      <div className="button-group">
        <Link to="/profile/add-kitchen" className="feature-button1">
          <img src={arrowCircleRight} alt="Arrow" className="button-icon" />
          List your kitchen
        </Link>
        <Link to="/aibuilder" className="feature-button1">
          <img src={arrowCircleRight} alt="Arrow" className="button-icon" />
          AI Builder
        </Link>
        <Link to="/kitchenwaitinglist" className="feature-button1">
          <img src={arrowCircleRight} alt="Arrow" className="button-icon" />
          Waiting List
        </Link>
      </div>
    </div>
  );
};

export default HeroSection;
