import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { useKitchen } from "../../context/KitchenContext";
import { useBooking } from "../../context/BookingContext";
import Slider from "react-slick";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaTimes,
  FaTimesCircle,
  FaCheckCircle,
} from "react-icons/fa";
import "./Kitchen.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Kitchen = () => {
  const { kitchenID } = useParams();
  const [kitchen, setKitchen] = useState(null);

  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [showRenterTermsModal, setshowRenterTermsModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchKitchenById, user, loadingUser } = useKitchen();
  const { initiateStripePayment, checkKitchenAvailability } = useBooking();
  const [agreed, setAgreed] = useState(false);
  const link = window.location.href;
  const isHourly = kitchen?.BookingType === "hour";

  const [availabilityError, setAvailabilityError] = useState("");
  const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);
  const [hourlyBreakdown, setHourlyBreakdown] = useState([]);

  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [missingDocs, setMissingDocs] = useState([]);

  useEffect(() => {
    const fetchKitchen = async () => {
      try {
        const kitchenData = await fetchKitchenById(kitchenID);
        setKitchen(kitchenData);
      } catch (error) {
        console.error("Error fetching kitchen:", error);
      }
    };
    fetchKitchen();
  }, [kitchenID, fetchKitchenById, loadingUser, navigate, user]);

  const toggleRenterTermsModal = () =>
    setshowRenterTermsModal(!showRenterTermsModal);

  const handleBooking = async () => {
    if (user == null) {
      window.location.href = "/login";
      return;
    }

    if (!agreed) {
      alert("Please agree to the Terms and Conditions before booking.");
      return;
    }

    const isValid = await validateUserForBooking();
    if (!isValid) return;

    if (!checkinDate || !checkoutDate) {
      alert("Please select check-in and check-out dates.");
      return;
    }

    if (isHourly && (startTime === "" || endTime === "")) {
      alert("Please select start and end times for your hourly booking.");
      return;
    }

    // Check min booking requirement
    if (!meetsMinBookingRequirement()) {
      return;
    }

    setAvailabilityError("");
    setIsCheckingAvailability(true);

    const formattedCheckin = checkinDate.toISOString().split("T")[0];
    const formattedCheckout = checkoutDate.toISOString().split("T")[0];

    try {
      const availabilityResponse = await checkKitchenAvailability(
        kitchenID,
        formattedCheckin,
        formattedCheckout,
        startTime,
        endTime
      );
      setIsCheckingAvailability(false);

      if (!availabilityResponse.available) {
        setAvailabilityError(
          availabilityResponse.message ||
            "The kitchen is not available for these dates/times."
        );
        return;
      }

      // Additional validation for time range on same-day booking:
      const checkinCombined = new Date(
        `${formattedCheckin}T${startTime.toString().padStart(2, "0")}:00`
      );
      const checkoutCombined = new Date(
        `${formattedCheckout}T${endTime.toString().padStart(2, "0")}:00`
      );
      if (checkoutCombined <= checkinCombined) {
        alert(
          "Check-out time must be after check-in time for hourly booking. If it's overnight, select an end time earlier than start time to indicate next-day end."
        );
        return;
      }

      const serviceFee = totalAmount * 0.15;
      const securityDeposit = parseFloat(kitchen.SecurityDeposit) || 0;
      const finalTotal = totalAmount + serviceFee + securityDeposit;

      const bookingDetails = {
        kitchenID,
        kitchenOwnerID: kitchen.UserID,
        userID: user.userID,
        kitchenTitle: kitchen.Title,
        bookingType: kitchen.BookingType,
        totalAmount: finalTotal,
        checkinDate: formattedCheckin,
        checkoutDate: formattedCheckout,
        startTime: startTime,
        endTime: endTime,
        totalHours: totalHours,
        totalDays: totalDays,
      };

      await initiateStripePayment(bookingDetails);
    } catch (error) {
      setIsCheckingAvailability(false);
      if (error.message) {
        setAvailabilityError(error.message);
      } else if (error.error) {
        setAvailabilityError(error.error);
      } else {
        setAvailabilityError("An unexpected error occurred. Please try again.");
      }
    }
  };

  const meetsMinBookingRequirement = () => {
    const min = parseFloat(kitchen.MinBooking || 0);
    if (isHourly) {
      if (totalHours < min) {
        alert(
          `Minimum booking requirement is ${min} hour(s), but you selected ${totalHours.toFixed(
            2
          )} hour(s). Please adjust your times.`
        );
        return false;
      }
    } else {
      if (totalDays < min) {
        alert(
          `Minimum booking requirement is ${min} day(s), but you selected ${totalDays.toFixed(
            2
          )} day(s). Please adjust your dates.`
        );
        return false;
      }
    }
    return true;
  };

  const calculateTotalAmount = () => {
    if (!kitchen) return;
    try {
      if (isHourly) {
        if (
          !checkinDate ||
          !checkoutDate ||
          startTime === "" ||
          endTime === ""
        ) {
          setTotalHours(0);
          setTotalAmount(0);
          setHourlyBreakdown([]);
          return;
        }
        const hours = calculateHourlyDuration(
          checkinDate,
          checkoutDate,
          startTime,
          endTime
        );
        setTotalHours(hours);

        const amount = calculateHourlyAmount(
          hours,
          checkinDate,
          checkoutDate,
          startTime,
          endTime
        );
        setTotalAmount(amount);
      } else {
        if (!checkinDate || !checkoutDate) {
          setTotalDays(0);
          setTotalAmount(0);
          return;
        }
        const daysDifference =
          (checkoutDate.getTime() - checkinDate.getTime()) /
          (1000 * 60 * 60 * 24);

        if (daysDifference <= 0) {
          setTotalDays(0);
          setTotalAmount(0);
          return;
        }
        setTotalDays(daysDifference);
        const amount = daysDifference * (parseFloat(kitchen.DayRate) || 0);
        setTotalAmount(amount);
        setHourlyBreakdown([]);
      }
    } catch (error) {
      console.error("Error calculating total amount:", error);
      setTotalAmount(0);
      if (isHourly) setTotalHours(0);
      else setTotalDays(0);
    }
  };

  const calculateHourlyDuration = (startDate, endDate, startH, endH) => {
    const startFull = new Date(
      `${startDate.toISOString().split("T")[0]}T${startH.padStart(2, "0")}:00`
    );
    const endFull = new Date(
      `${endDate.toISOString().split("T")[0]}T${endH.padStart(2, "0")}:00`
    );
    let diff = (endFull - startFull) / (1000 * 60 * 60);
    if (diff <= 0) {
      // overnight scenario
      diff += 24;
    }
    return diff;
  };

  const calculateHourlyAmount = (hours, startDate, endDate, startH, endH) => {
    if (!kitchen || !kitchen.RateEntries) return 0;

    const segments = getHourlySegments(startDate, endDate, startH, endH);
    let breakdownItems = [];
    let currentInterval = null;

    for (let i = 0; i < segments.length; i++) {
      const { dayOfWeek, hour } = segments[i];

      const dayEntries = kitchen.RateEntries.filter(
        (r) => r.day.toLowerCase() === dayOfWeek
      );
      const matchedEntry = getMatchingEntry(dayEntries, hour);

      // If no entry covers this hour, we just don't add it. The user will fail availability check if needed.
      if (!matchedEntry) {
        continue;
      }

      const hourRate = parseFloat(matchedEntry.price);

      if (
        currentInterval &&
        currentInterval.dayOfWeek === dayOfWeek &&
        currentInterval.hourlyRate === hourRate &&
        currentInterval.endHour === hour
      ) {
        currentInterval.endHour = hour + 1;
        currentInterval.hoursCount += 1;
        currentInterval.subtotal += hourRate;
      } else {
        if (currentInterval) {
          breakdownItems.push({ ...currentInterval });
        }
        currentInterval = {
          dayOfWeek,
          startHour: hour,
          endHour: hour + 1,
          hourlyRate: hourRate,
          hoursCount: 1,
          subtotal: hourRate,
        };
      }
    }

    if (currentInterval) breakdownItems.push(currentInterval);

    const total = breakdownItems.reduce((sum, item) => sum + item.subtotal, 0);
    setHourlyBreakdown(breakdownItems);
    return total;
  };

  function getMatchingEntry(dayEntries, hour) {
    // Return the entry covering this hour, or null if none
    for (let entry of dayEntries) {
      const s = parseInt(entry.start, 10);
      const e = parseInt(entry.end, 10);

      let price = parseFloat(entry.price);
      if (!price || price <= 0) {
        // If no valid price, this entry is useless
        continue;
      }

      if (e > s) {
        // Normal interval: [s,e)
        if (hour >= s && hour < e) return entry;
      } else {
        // Overnight interval: covers [s,24) and [0,e)
        if ((hour >= s && hour < 24) || (hour >= 0 && hour < e)) return entry;
      }
    }

    // No entry covers this hour
    return null;
  }

  function getHourlySegments(startDate, endDate, startH, endH) {
    let current = new Date(
      `${startDate.toISOString().split("T")[0]}T${startH.padStart(2, "0")}:00`
    );
    let end = new Date(
      `${endDate.toISOString().split("T")[0]}T${endH.padStart(2, "0")}:00`
    );

    if (end <= current) {
      // overnight scenario
      end = new Date(end.getTime() + 24 * 60 * 60 * 1000);
    }

    const segments = [];
    while (current < end) {
      const dayOfWeek = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
      })
        .format(current)
        .toLowerCase();

      segments.push({
        dayOfWeek,
        hour: current.getHours(),
      });
      current = new Date(current.getTime() + 60 * 60 * 1000);
    }
    return segments;
  }

  useEffect(() => {
    calculateTotalAmount();
    // eslint-disable-next-line
  }, [checkinDate, checkoutDate, startTime, endTime, isHourly, kitchen]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const session_id = urlParams.get("session_id");
    if (session_id) {
      setModalContent({
        type: "success",
        message: "Thank you for your payment!",
        details: `Transaction ID: ${session_id}`,
      });
      setShowBookingModal(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (availabilityError) {
      const timer = setTimeout(() => {
        setAvailabilityError("");
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [availabilityError]);

  const handleCloseErrorMessage = () => {
    setAvailabilityError("");
  };

  const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  const formatTime = (time) => {
    if (time == null || isNaN(parseInt(time, 10))) {
      return "00:00";
    }
    const formattedTime = parseInt(time, 10);
    return `${formattedTime.toString().padStart(2, "0")}:00`;
  };

  const formatHour = (h) => {
    return (h % 24).toString().padStart(2, "0");
  };

  const toggleBookingModal = () => setShowBookingModal(false);

  const toggleDropdown = (section) => {
    setActiveDropdown(activeDropdown === section ? null : section);
  };

  if (!kitchen) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p className="loading-text">Looking kitchen for you...</p>
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: kitchen.Images && kitchen.Images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const isNewAmenitiesFormat =
    kitchen.Amenities &&
    typeof kitchen.Amenities === "object" &&
    !Array.isArray(kitchen.Amenities);

  // Adjust end time options for same-day bookings to start at least 1 hour after startTime.
  const getHourOptions = (isEnd = false) => {
    let start = 0;
    const sameDayBooking =
      checkinDate &&
      checkoutDate &&
      checkinDate.toDateString() === checkoutDate.toDateString();

    if (isEnd && sameDayBooking && startTime !== "") {
      const startVal = parseInt(startTime, 10);
      start = startVal + 1; // end times start at least one hour after start
    }

    const options = [];
    for (let i = start; i < 24; i++) {
      options.push(
        <option key={i} value={i}>
          {i.toString().padStart(2, "0")}:00
        </option>
      );
    }
    return options;
  };

  const validateUserForBooking = async () => {
    const missing = [];

    if (user.idVerificationStatus !== "verified") {
      missing.push("ID Verification");
    }
    if (!user.documents?.foodHandler) {
      missing.push("Food Handler Certificate");
    }
    if (!user.documents?.insurance) {
      missing.push("Insurance Document");
    }

    if (missing.length > 0) {
      setMissingDocs(missing);
      setShowVerificationModal(true);
      return false;
    }

    return true;
  };

  return (
    <>
      <div className="main-kitchen-page">
        <Navbar />
        <div className="kitchen-page">
          <div className="kitchen-image-slider">
            <Slider {...settings}>
              {kitchen.Images && kitchen.Images.length > 0 ? (
                kitchen.Images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`Kitchen image ${index + 1}`}
                      className="kitchen-slider-image"
                      onError={(e) => {
                        e.target.src =
                          "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/assets/KitchenComingSoon.png";
                      }}
                    />
                  </div>
                ))
              ) : (
                <div>
                  <img
                    src="https://airkitchenz-assets.s3.us-west-1.amazonaws.com/assets/KitchenComingSoon.png"
                    alt="Default Kitchen"
                    className="kitchen-slider-image"
                  />
                </div>
              )}
            </Slider>
          </div>

          <div className="kitchen-content">
            <div className="kitchen-details-container">
              <div className="kitchen-header">
                <h1>{kitchen.Title || "Untitled Kitchen"}</h1>
                <p>
                  <strong>Description:</strong> {kitchen.Description || "N/A"}
                </p>

                <div className="kitchen-details-row">
                  <p>
                    <strong>Type:</strong>{" "}
                    {capitalizeFirstLetter(kitchen.Category) || "N/A"}
                  </p>
                  <p>
                    <strong>Size:</strong>{" "}
                    {capitalizeFirstLetter(kitchen.Size) || "N/A"}
                  </p>
                  <p>
                    <strong>City:</strong> {kitchen.City || "N/A"}
                  </p>
                </div>
                {/* <p>Exact location provided after booking confirmation.</p> */}
              </div>

              <div
                className={`dropdown-section ${
                  activeDropdown === "price" ? "active" : ""
                }`}
              >
                <div
                  className="dropdown-header"
                  onClick={() => toggleDropdown("price")}
                >
                  Price Details
                </div>
                <div className="dropdown-content">
                  <p>
                    {isHourly
                      ? `Hourly Rate: $${kitchen.HourlyRate || "N/A"}`
                      : `Daily Rate: $${kitchen.DayRate || "N/A"}`}
                  </p>
                  <p>
                    Minimum Booking:{" "}
                    {isHourly
                      ? `${kitchen.MinBooking || "N/A"} hours`
                      : `${kitchen.MinBooking || "N/A"} day(s)`}
                  </p>
                  <p>Security Deposit: ${kitchen.SecurityDeposit || "N/A"}</p>
                  <p>Cleaning Fee: ${kitchen.CleaningFee || "N/A"}</p>
                </div>
              </div>

              <div
                className={`dropdown-section ${
                  activeDropdown === "amenities" ? "active" : ""
                }`}
              >
                <div
                  className="dropdown-header"
                  onClick={() => toggleDropdown("amenities")}
                >
                  Amenities
                </div>
                <div className="dropdown-content">
                  {kitchen.Amenities &&
                  Object.keys(kitchen.Amenities).length > 0 ? (
                    isNewAmenitiesFormat ? (
                      Object.entries(kitchen.Amenities).map(
                        ([category, amenitiesList], index) => (
                          <div key={index}>
                            <h4>{capitalizeFirstLetter(category)}</h4>
                            <ul>
                              {amenitiesList.map((amenity, idx) => (
                                <li key={idx}>{amenity}</li>
                              ))}
                            </ul>
                          </div>
                        )
                      )
                    ) : (
                      <p>No amenities available</p>
                    )
                  ) : (
                    <p>No amenities available</p>
                  )}
                </div>
              </div>

              <div
                className={`dropdown-section ${
                  activeDropdown === "rates" ? "active" : ""
                }`}
              >
                <div
                  className="dropdown-header"
                  onClick={() => toggleDropdown("rates")}
                >
                  Rates and Availability
                </div>
                <div className="dropdown-content">
                  {kitchen.RateEntries && kitchen.RateEntries.length > 0 ? (
                    <table className="rates-table">
                      <thead>
                        <tr>
                          <th>Occurs</th>
                          <th>Start</th>
                          <th>End</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {kitchen.RateEntries.map((entry, index) => {
                          const start = new Date(entry.startDateTime);
                          const end = new Date(entry.endDateTime);
                          const price = parseFloat(entry.price);
                          return (
                            <tr key={index}>
                              <td>
                                {entry.recurring ? "Every week" : "One time"}
                              </td>
                              <td>
                                {entry.recurring
                                  ? `${start.toLocaleDateString(undefined, {
                                      weekday: "long",
                                    })}, ${start.toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}`
                                  : !isNaN(start.getTime())
                                  ? start.toLocaleString()
                                  : "Invalid Date"}
                              </td>
                              <td>
                                {entry.recurring
                                  ? `${end.toLocaleDateString(undefined, {
                                      weekday: "long",
                                    })}, ${end.toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}`
                                  : !isNaN(end.getTime())
                                  ? end.toLocaleString()
                                  : "Invalid Date"}
                              </td>
                              <td>
                                ${!isNaN(price) ? price.toFixed(2) : "NaN"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <p>No rate entries available.</p>
                  )}
                  {kitchen.BlockedDates && kitchen.BlockedDates.length > 0 && (
                    <div className="blocked-dates-section">
                      <h4>Blocked Dates:</h4>
                      <ul>
                        {kitchen.BlockedDates.map((d, i) => (
                          <li key={i}>{new Date(d).toLocaleDateString()}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="kitchen-booking-section">
              <h3>
                ${isHourly ? kitchen.HourlyRate : kitchen.DayRate} per{" "}
                {isHourly ? "hour" : "day"}
              </h3>

              <div className="booking-form">
                <label>Check-In Date</label>
                <DatePicker
                  selected={checkinDate}
                  onChange={(date) => {
                    setCheckinDate(date);
                    if (checkoutDate && date && date > checkoutDate) {
                      setCheckoutDate(null);
                    }
                  }}
                  minDate={new Date()}
                  placeholderText="Select check-in date"
                  excludeDates={kitchen.BlockedDates?.map((d) => new Date(d))}
                />

                <label>Check-Out Date</label>
                <DatePicker
                  selected={checkoutDate}
                  onChange={(date) => {
                    setCheckoutDate(date);
                  }}
                  minDate={checkinDate || new Date()}
                  placeholderText="Select check-out date"
                  disabled={!checkinDate}
                  excludeDates={kitchen.BlockedDates?.map((d) => new Date(d))}
                />

                <div className="time-selection">
                  <div className="time-field">
                    <label>Start Time</label>
                    <select
                      value={startTime}
                      onChange={(e) => {
                        setStartTime(e.target.value);
                        if (
                          endTime &&
                          parseInt(e.target.value, 10) >= parseInt(endTime, 10)
                        ) {
                          setEndTime("");
                        }
                      }}
                    >
                      <option value="">Select Start Time</option>
                      {getHourOptions(false)}
                    </select>
                  </div>
                  <div className="time-field">
                    <label>End Time</label>
                    <select
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      disabled={!startTime}
                    >
                      <option value="">Select End Time</option>
                      {startTime !== "" && getHourOptions(true)}
                    </select>
                  </div>
                </div>

                {availabilityError && (
                  <div className="booking-error-message">
                    <span>{availabilityError}</span>
                    <FaTimes
                      className="error-close-icon"
                      onClick={handleCloseErrorMessage}
                    />
                  </div>
                )}

                {isCheckingAvailability && (
                  <div className="loading-message">
                    <span>Checking availability...</span>
                  </div>
                )}

                {checkinDate && checkoutDate && (
                  <div className="cost-breakdown">
                    <table>
                      <tbody>
                        {isHourly &&
                          hourlyBreakdown.length > 0 &&
                          hourlyBreakdown.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {capitalizeFirstLetter(item.dayOfWeek)}{" "}
                                {formatHour(item.startHour)}:00 -{" "}
                                {formatHour(item.endHour)}:00 &nbsp; ($
                                {item.hourlyRate.toFixed(2)}/hr x{" "}
                                {item.hoursCount} hr)
                              </td>
                              <td>${item.subtotal.toFixed(2)}</td>
                            </tr>
                          ))}
                        {!isHourly && (
                          <tr>
                            <td>{`$${kitchen.DayRate} x ${totalDays.toFixed(
                              2
                            )} day(s)`}</td>
                            <td>${totalAmount.toFixed(2)}</td>
                          </tr>
                        )}

                        {isHourly && hourlyBreakdown.length > 0 && (
                          <tr>
                            <td>Subtotal</td>
                            <td>${totalAmount.toFixed(2)}</td>
                          </tr>
                        )}

                        {(() => {
                          const serviceFee = totalAmount * 0.15;
                          const securityDeposit =
                            parseFloat(kitchen.SecurityDeposit) || 0;
                          const finalTotal =
                            totalAmount + serviceFee + securityDeposit;

                          return (
                            <>
                              <tr>
                                <td>Convenience Fee</td>
                                <td>${serviceFee.toFixed(2)}</td>
                              </tr>
                              <tr>
                                <td>Security Deposit (*refundable)</td>
                                <td>${securityDeposit.toFixed(2)}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Total</strong>
                                </td>
                                <td>
                                  <strong>${finalTotal.toFixed(2)}</strong>
                                </td>
                              </tr>
                            </>
                          );
                        })()}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="renter-terms-container">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={agreed}
                    onChange={(e) => setAgreed(e.target.checked)}
                  />
                  <label htmlFor="terms" onClick={toggleRenterTermsModal}>
                    I agree to the{" "}
                    <span className="terms-link">Terms and Conditions</span>
                  </label>
                </div>

                <button
                  className="booking-btn"
                  onClick={handleBooking}
                  disabled={isCheckingAvailability}
                >
                  {isCheckingAvailability ? "Checking..." : "Book Now"}
                </button>
              </div>

              <div className="social-links">
                <span>Share</span>
                <a
                  href={`https://www.instagram.com/direct/new/?text=${encodeURIComponent(
                    "Check out this kitchen: " + link
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Share on Instagram DM"
                >
                  <FaInstagram />
                </a>
                <a
                  href={`https://m.me/?link=${encodeURIComponent(link)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Share on Facebook Messenger DM"
                >
                  <FaFacebook />
                </a>
                <a
                  href={`https://www.linkedin.com/messaging/compose?body=${encodeURIComponent(
                    "Check out this kitchen: " + link
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Share on LinkedIn DM"
                >
                  <FaLinkedin />
                </a>
                <a
                  href={`https://twitter.com/messages/compose?text=${encodeURIComponent(
                    "Check out this kitchen: " + link
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Share via Twitter DM"
                >
                  <FaTwitter />
                </a>
                <a
                  href={`mailto:?subject=Check out this kitchen&body=${encodeURIComponent(
                    "Check out this kitchen: " + link
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Share via Email"
                >
                  <FaEnvelope />
                </a>
              </div>
            </div>
          </div>

          {showBookingModal && (
            <div
              className="kitchen-page-modal-overlay"
              onClick={toggleBookingModal}
            >
              <div
                className="kitchen-page-modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="kitchen-page-modal-close"
                  onClick={toggleBookingModal}
                  aria-label="Close Modal"
                >
                  &times;
                </button>
                {modalContent.type === "success" ? (
                  <FaCheckCircle className="kitchen-page-modal-icon success" />
                ) : (
                  <FaTimesCircle className="kitchen-page-modal-icon error" />
                )}
                <h2>{modalContent.message}</h2>
                <p>{modalContent.details}</p>
                {modalContent.type === "success" && (
                  <a
                    href="/profile/my-bookings"
                    className="kitchen-page-modal-link"
                  >
                    View My Bookings
                  </a>
                )}
              </div>
            </div>
          )}

          {showRenterTermsModal && (
            <div
              className="kitchen-page-modal-overlay"
              onClick={toggleRenterTermsModal}
            >
              <div
                className="kitchen-page-modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <FaTimes
                  className="kitchen-page-modal-close"
                  onClick={toggleRenterTermsModal}
                  aria-label="Close Terms Modal"
                />
                <div className="kitchen-page-modal-body">
                  <h2>Kitchen Renter Terms and Conditions</h2>
                  <p>
                    This Rental Agreement and Disclaimer (“Agreement”) is
                    entered into between the renter (“Renter”) and AirKitchenz,
                    governing the use of kitchens rented through the AirKitchenz
                    platform. By checking the box below, the Renter acknowledges
                    and agrees to comply with all terms and conditions herein.
                  </p>

                  <h3>1. Insurance Requirements</h3>
                  <p>
                    The Renter agrees to maintain adequate insurance coverage
                    for the duration of the rental period, including but not
                    limited to liability insurance and any other insurance
                    deemed necessary by AirKitchenz. The Renter must provide
                    proof of insurance and identification (driver’s license or
                    passport) to cover any liability or damage occurring during
                    the rental period.
                  </p>

                  <h3>2. Documentation Requirements</h3>
                  <p>
                    The Renter is required to upload the following documentation
                    to the Platform prior to the commencement of the rental
                    period:
                  </p>
                  <ul>
                    <li>Proof of identity (driver’s license or passport)</li>
                    <li>Liability insurance</li>
                    <li>Food handler certification</li>
                  </ul>
                  <p>
                    Failure to upload the required documentation may result in
                    cancellation of the booking and forfeiture of any deposits
                    paid.
                  </p>

                  <h3>3. Deposit and Damage Protection</h3>
                  <p>
                    The Platform will collect a security deposit from the Renter
                    at the time of booking. This deposit will be held by the
                    Platform for 66 hours after the Kitchen booking is
                    finalized. The Renter acknowledges that they are solely
                    responsible for any damage to the rented kitchen, missing
                    items, or any other issues that occur during the rental
                    period.
                  </p>
                  <p>
                    The Kitchen Owner has 66 hours after the booking period ends
                    to report any issues or damages to the Kitchen or equipment.
                    If no issues or damages are reported within this timeframe,
                    the Platform will return the security deposit to the Renter.
                    If any damage, missing items, or other costs exceed the
                    deposit, AirKitchenz is authorized to charge the Renter’s
                    credit card for the additional amount necessary to cover the
                    expenses incurred.
                  </p>

                  <h3>4. Clean-Up Responsibility and Documentation</h3>
                  <p>
                    The Renter agrees to leave the rented kitchen in the same
                    condition as it was found, including but not limited to
                    cleaning up after use, disposing of waste properly, and
                    returning any borrowed items to their original location.
                  </p>
                  <p>
                    To ensure the condition of the kitchen is documented, the
                    Renter is encouraged to take photographs of the kitchen upon
                    arrival, clearly capturing the initial state and condition
                    of the kitchen and its equipment. Upon completing the use of
                    the kitchen, the Renter should take additional photographs
                    to document the state in which the kitchen and all equipment
                    were left.
                  </p>
                  <p>
                    Any disputes regarding the condition of the kitchen or
                    equipment must be substantiated with clear photographic
                    evidence taken before and after the rental period.
                    AirKitchenz reserves the right to make a final decision
                    based on the evidence provided.
                  </p>

                  <h3>5. Liability and Indemnification</h3>
                  <p>
                    The Renter agrees that AirKitchenz shall not be held liable
                    for any damage, missing items, accidents, or issues that
                    occur during the rental period. The Renter further agrees to
                    indemnify and hold AirKitchenz harmless from any claims,
                    damages, or liabilities arising out of the Renter’s use of
                    the rented kitchen.
                  </p>
                  <p>
                    AirKitchenz acts solely as a facilitator and is not
                    responsible for any agreements, actions, or negligence on
                    the part of the kitchen owner or the Renter. All liability
                    rests with the respective parties involved in the rental
                    agreement.
                  </p>

                  <h3>6. Platform Facilitation and Transaction Requirements</h3>
                  <p>
                    The Renter acknowledges and agrees that AirKitchenz does not
                    own any of the kitchen locations listed on its platform.
                    AirKitchenz serves solely as a facilitator of transactions
                    between the Renter and kitchen owners.
                  </p>
                  <p>
                    All transactions, payments, and communications must be
                    conducted exclusively through the AirKitchenz platform. No
                    exchange of personal contact information, including phone
                    numbers or email addresses, is allowed between the Renter
                    and the Kitchen Owner for security reasons. Any arrangements
                    made outside of the AirKitchenz platform are strictly
                    prohibited.
                  </p>

                  <h3>7. Credit Card Authorization and Charges</h3>
                  <p>
                    The Renter authorizes AirKitchenz to keep the credit cards
                    used for payment on file. In the event of damage, missing
                    items, or if costs exceed the deposit amount, the Renter
                    agrees that AirKitchenz has the right to charge the Renter’s
                    credit cards for the additional amount necessary to cover
                    the expenses incurred.
                  </p>
                  <p>
                    The Renter understands that this authorization includes
                    charging the credit cards for any costs related to damages,
                    missing items, or other expenses not covered by the initial
                    security deposit.
                  </p>

                  <h3>8. Communication Protocol</h3>
                  <p>
                    The Renter agrees that all communications between the Renter
                    and kitchen owners must be conducted exclusively through the
                    AirKitchenz platform. Renters and kitchen owners are
                    prohibited from exchanging phone numbers, email addresses,
                    or other contact information outside of the AirKitchenz
                    platform.
                  </p>

                  <h3>9. Rating System and Reviews</h3>
                  <p>
                    AirKitchenz maintains a rating system that allows both
                    kitchen renters and kitchen owners to provide feedback and
                    rate each other based on their rental experience.
                  </p>
                  <p>Kitchen Renters will rate the kitchens based on:</p>
                  <ul>
                    <li>Cleanliness of the kitchen</li>
                    <li>Functionality and condition of equipment</li>
                    <li>Accuracy of the kitchen listing description</li>
                    <li>Overall experience</li>
                  </ul>
                  <p>Kitchen Owners will rate the Kitchen Renters based on:</p>
                  <ul>
                    <li>Cleanliness after use</li>
                    <li>Adherence to kitchen rules</li>
                    <li>Communication and responsiveness</li>
                    <li>Respect for the kitchen and equipment</li>
                  </ul>

                  <h3>10. Enforcement and Penalties</h3>
                  <p>
                    Any party found to be in violation of the communication
                    protocol will be subject to suspension or permanent removal
                    from the AirKitchenz platform.
                  </p>

                  <h3>11. AirKitchenz Service Fee</h3>
                  <p>
                    AirKitchenz charges a service fee of 15% of the total rental
                    cost for facilitating the transaction and providing platform
                    services.
                  </p>

                  <h3>12. Governing Law</h3>
                  <p>
                    This Agreement shall be governed by and construed in
                    accordance with the laws of the United States and the states
                    where the transactions take place.
                  </p>

                  <h3>13. Entire Agreement</h3>
                  <p>
                    This Agreement constitutes the entire agreement between the
                    Renter and AirKitchenz regarding the rental of the kitchen
                    and supersedes all prior agreements and understandings,
                    whether written or oral.
                  </p>

                  <p>
                    By checking the box below, the Renter acknowledges that they
                    have read, understood, and agree to be bound by all terms
                    and conditions herein. Failure to comply with any provision
                    of this Agreement may result in termination of the rental
                    agreement and forfeiture of the Renter’s deposit.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {showVerificationModal && (
          <div className="verification-modal-overlay">
            <div className="verification-modal">
              <h2>Profile Verification Required</h2>
              <p>
                To book a kitchen, please ensure the following requirements are
                completed:
              </p>
              <ul>
                {missingDocs.map((doc, index) => (
                  <li key={index}>{doc}</li>
                ))}
              </ul>
              <button
                className="verification-modal-btn"
                onClick={() => {
                  setShowVerificationModal(false);
                  navigate("/profile/settings");
                }}
              >
                Go to Profile
              </button>
            </div>
          </div>
        )}
        <div className="register-message">
          <p>
            Own a kitchen?{" "}
            <Link to={"/profile/add-kitchen"} className="register-link">
              Register your kitchen now
            </Link>{" "}
            to match with kitchen seekers!
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Kitchen;
