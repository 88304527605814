// src/pages/Affiliate.js
import React from "react";
import { Link } from "react-router-dom";
import { FaUtensils, FaRegCalendarCheck } from "react-icons/fa";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./Affiliate.css";

const Affiliate = () => {
  return (
    <div className="affiliate-page">
      <Navbar />

      {/* Header Section - full width */}
      <header className="affiliate-header">
        <div className="affiliate-header-content">
          <h1>AirKitchenz Affiliate Program</h1>
          <p>Earn by promoting our innovative kitchen rental platform.</p>
        </div>
      </header>

      {/* Campaigns Section - centered container with two side-by-side cards */}
      <section className="affiliate-content">
        <div className="campaign">
          <div className="campaign-icon">
            <FaUtensils size={50} color="#e63946" />
          </div>
          <h2>Campaign #1: Kitchen Sign-Up Program</h2>
          <p>
            Encourage restaurant owners and commercial kitchen operators to list
            their kitchens on AirKitchenz.
          </p>
          <ul>
            <li>
              Earn $10 per kitchen sign-up using your unique referral code.
            </li>
            <li>
              Earn 10% commission on the first two bookings (minimum 4-hour
              bookings) made by each kitchen you refer.
            </li>
            <li>
              <strong>Bonus for Kitchen Owners:</strong> 3 Months
              Commission-Free! (Standard credit card processing fees still
              apply)
            </li>
          </ul>
          <a
            href="mailto:contact@airkitchenz.com?subject=Inquiry"
            className="affiliate-button"
          >
            Sign Up &amp; Start Earning
          </a>
        </div>

        <div className="campaign">
          <div className="campaign-icon">
            <FaRegCalendarCheck size={50} color="#e63946" />
          </div>
          <h2>Campaign #2: Kitchen Booking Promotion</h2>
          <p>
            Drive more kitchen bookings by offering discounts to new kitchen
            renters.
          </p>
          <ul>
            <li>
              Earn 5% commission per qualifying booking using your unique
              referral code.
            </li>
            <li>
              <strong>Bonus for Kitchen Renters:</strong> 10% Off Their First
              Booking! (Bookings must be at least 4 hours)
            </li>
          </ul>
          <a
            href="mailto:contact@airkitchenz.com?subject=Inquiry"
            className="affiliate-button"
          >
            Join Now
          </a>
        </div>
      </section>

      {/* How It Works Section - full width */}
      <section className="affiliate-info">
        <div className="affiliate-info-content">
          <h2>How It Works</h2>
          <p>
            Our affiliate marketing program leverages an existing platform that
            handles tracking, reporting, and payouts. Once you sign up, you’ll
            receive a unique referral code that you can share on social media,
            blogs, and other promotional channels.
          </p>
          <p>
            When someone signs up or books a kitchen using your referral code,
            you earn rewards!!
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Affiliate;
