import React, { useState, useEffect } from "react";
import { useKitchen } from "../../context/KitchenContext";
import "./KitchenPrice.css";
import { FaTimes, FaEdit, FaTrash } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const KitchenPrice = ({
  nextStep,
  prevStep,
  editMode,
  kitchenData,
  updateKitchenData,
}) => {
  const { updateKitchen, updateKitchenEditMode } = useKitchen();
  const [loading, setLoading] = useState(false);

  // Basic pricing fields
  const [bookingType, setBookingType] = useState(
    kitchenData.bookingType || "hour"
  );
  const [hourlyRate, setHourlyRate] = useState(kitchenData.hourlyRate || "");
  const [dayRate, setDayRate] = useState(kitchenData.dayRate || "");
  const [cleaningFee, setCleaningFee] = useState(kitchenData.cleaningFee || "");
  const [minBooking, setMinBooking] = useState(kitchenData.minBooking || "");
  const [securityDeposit, setSecurityDeposit] = useState(
    kitchenData.securityDeposit || ""
  );

  // Availability slots: { startDateTime, endDateTime, price, recurring }
  const [rateEntries, setRateEntries] = useState(
    kitchenData.rateEntries?.length > 0 ? kitchenData.rateEntries : []
  );
  const [blockedDates, setBlockedDates] = useState(
    kitchenData.blockedDates || []
  );
  const [newBlockedDate, setNewBlockedDate] = useState(null);

  // New state variables for availability input:
  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);
  const [newAvailabilityPrice, setNewAvailabilityPrice] = useState("");
  const [newRecurring, setNewRecurring] = useState(false);
  const [editingSlotIndex, setEditingSlotIndex] = useState(null);

  useEffect(() => {
    if (editMode && kitchenData) {
      // In edit mode, load existing data
      setBookingType(kitchenData.bookingType || "hour");
      setHourlyRate(kitchenData.hourlyRate || "");
      setDayRate(kitchenData.dayRate || "");
      setCleaningFee(kitchenData.cleaningFee || "");
      setMinBooking(kitchenData.minBooking || "");
      setSecurityDeposit(kitchenData.securityDeposit || "");
      setBlockedDates(kitchenData.blockedDates || []);
      setRateEntries(
        kitchenData.rateEntries?.length > 0 ? kitchenData.rateEntries : []
      );
    } else if (!editMode) {
      // In create mode, reset fields
      setBookingType("hour");
      setHourlyRate("");
      setDayRate("");
      setCleaningFee("");
      setMinBooking("");
      setSecurityDeposit("");
      setRateEntries([]);
      setBlockedDates(kitchenData.blockedDates || []);
    }
  }, [editMode, kitchenData]);

  // Helper: format a time range as "HH:MM - HH:MM"
  const formatTimeRange = (start, end) => {
    return `${start.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })} - ${end.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  };

  // Check if a new slot overlaps any existing slot (exclude the one being edited)
  const isAvailabilityOverlap = (newSlot, excludeIndex = null) => {
    const newStart = new Date(newSlot.startDateTime).getTime();
    const newEnd = new Date(newSlot.endDateTime).getTime();
    return rateEntries.some((slot, index) => {
      if (index === excludeIndex) return false;
      const slotStart = new Date(slot.startDateTime).getTime();
      const slotEnd = new Date(slot.endDateTime).getTime();
      return newStart < slotEnd && newEnd > slotStart; // overlap condition
    });
  };

  // Add or update an availability slot
  const handleAddAvailabilitySlot = () => {
    if (!newStartDate || !newEndDate || !newAvailabilityPrice) {
      alert("Please select start/end times and a price.");
      return;
    }
    if (newEndDate <= newStartDate) {
      alert("End date/time must be after start date/time.");
      return;
    }
    const price = parseFloat(newAvailabilityPrice);
    if (isNaN(price) || price <= 0) {
      alert("Price must be a number greater than 0.");
      return;
    }

    const slot = {
      startDateTime: newStartDate.toISOString(),
      endDateTime: newEndDate.toISOString(),
      price: price,
      recurring: newRecurring,
    };

    // Editing
    if (editingSlotIndex !== null) {
      if (isAvailabilityOverlap(slot, editingSlotIndex)) {
        alert("The updated time slot overlaps with another slot.");
        return;
      }
      const updatedEntries = [...rateEntries];
      updatedEntries[editingSlotIndex] = slot;
      setRateEntries(updatedEntries);
      setEditingSlotIndex(null);
    }
    // Adding new
    else {
      if (isAvailabilityOverlap(slot)) {
        alert("This time slot overlaps with an existing slot.");
        return;
      }
      setRateEntries([...rateEntries, slot]);
    }

    // Reset input fields
    setNewStartDate(null);
    setNewEndDate(null);
    setNewAvailabilityPrice("");
    setNewRecurring(false);
  };

  // Edit a slot
  const handleEditAvailabilitySlot = (index) => {
    const slot = rateEntries[index];
    setEditingSlotIndex(index);
    setNewStartDate(new Date(slot.startDateTime));
    setNewEndDate(new Date(slot.endDateTime));
    setNewAvailabilityPrice(slot.price.toString());
    setNewRecurring(slot.recurring);
  };

  // Remove a slot
  const handleRemoveAvailabilitySlot = (index) => {
    if (editingSlotIndex === index) {
      setEditingSlotIndex(null);
      setNewStartDate(null);
      setNewEndDate(null);
      setNewAvailabilityPrice("");
      setNewRecurring(false);
    }
    setRateEntries(rateEntries.filter((_, i) => i !== index));
  };

  // Add a blocked date
  const handleAddBlockedDate = () => {
    if (!newBlockedDate) {
      alert("Please select a date to block.");
      return;
    }
    const dateStr = newBlockedDate.toISOString().split("T")[0];
    if (blockedDates.includes(dateStr)) {
      alert("This date is already blocked.");
      return;
    }
    setBlockedDates([...blockedDates, dateStr]);
    setNewBlockedDate(null);
  };

  // Remove a blocked date
  const handleRemoveBlockedDate = (dateStr) => {
    setBlockedDates(blockedDates.filter((d) => d !== dateStr));
  };

  // Cancel edit
  const handleCancelEdit = () => {
    setEditingSlotIndex(null);
    setNewStartDate(null);
    setNewEndDate(null);
    setNewAvailabilityPrice("");
    setNewRecurring(false);
  };

  // Save and go next
  const handleNext = async (e) => {
    e.preventDefault();

    if (bookingType === "hour" && !hourlyRate) {
      alert("Please provide an hourly rate.");
      return;
    }
    if (bookingType === "day" && !dayRate) {
      alert("Please provide a daily rate.");
      return;
    }
    if (parseFloat(minBooking) <= 0) {
      alert("Minimum booking must be greater than 0.");
      return;
    }
    // Validate availability slots
    for (let slot of rateEntries) {
      if (
        !slot.startDateTime ||
        !slot.endDateTime ||
        !slot.price ||
        parseFloat(slot.price) <= 0
      ) {
        alert("Please provide valid details for all availability slots.");
        return;
      }
    }

    // Prepare data
    const pricingData = {
      bookingType,
      hourlyRate,
      dayRate,
      cleaningFee,
      minBooking,
      securityDeposit,
      rateEntries,
      blockedDates,
    };
    const updatedKitchenData = { ...kitchenData, ...pricingData };

    setLoading(true);
    try {
      if (editMode) {
        // Update in edit mode
        await updateKitchenEditMode(kitchenData.KitchenID, updatedKitchenData);
      } else {
        // Create in normal mode
        await updateKitchen(updatedKitchenData);
      }
      // Update local data
      updateKitchenData(pricingData);
      nextStep();
    } catch (error) {
      console.error("Failed to update pricing:", error.message);
      alert("Failed to save pricing details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // (Optional) Generate events for recurring slots (for a calendar preview)
  const generateRecurringEvents = (slot, baseIndex) => {
    const events = [];
    const weeksToShow = 4;
    const baseStart = new Date(slot.startDateTime);
    const baseEnd = new Date(slot.endDateTime);
    const duration = baseEnd.getTime() - baseStart.getTime();

    // Find the next occurrence of that weekday
    let current = new Date();
    current.setHours(baseStart.getHours(), baseStart.getMinutes(), 0, 0);
    while (current.getDay() !== baseStart.getDay() || current < new Date()) {
      current.setDate(current.getDate() + 1);
    }
    for (let i = 0; i < weeksToShow; i++) {
      const eventStart = new Date(current);
      const eventEnd = new Date(eventStart.getTime() + duration);
      events.push({
        id: baseIndex,
        title: `$${slot.price} (${formatTimeRange(
          eventStart,
          eventEnd
        )}) (Recurring)`,
        start: eventStart,
        end: eventEnd,
        recurring: true,
        baseSlot: slot,
      });
      current.setDate(current.getDate() + 7);
    }
    return events;
  };

  // (Optional) Prepare events for a calendar preview
  let events = [];
  rateEntries.forEach((slot, index) => {
    if (slot.recurring) {
      events = events.concat(generateRecurringEvents(slot, index));
    } else {
      const start = new Date(slot.startDateTime);
      const end = new Date(slot.endDateTime);
      events.push({
        id: index,
        title: `$${slot.price} (${formatTimeRange(start, end)})`,
        start: start,
        end: end,
        recurring: false,
      });
    }
  });

  return (
    <form onSubmit={handleNext} className="kitchen-price">
      <h2 className="form-title">
        {editMode ? "Edit Pricing Details" : "Pricing Details"}
      </h2>

      <div className="price-group">
        {/* Hourly or Daily Rate */}
        {bookingType === "hour" ? (
          <div className="price-form-group">
            <label>Hourly Rate</label>
            <input
              type="number"
              placeholder="Enter hourly rate"
              value={hourlyRate}
              onChange={(e) => setHourlyRate(e.target.value)}
            />
          </div>
        ) : (
          <div className="price-form-group">
            <label>Day Rate</label>
            <input
              type="number"
              placeholder="Enter day rate"
              value={dayRate}
              onChange={(e) => setDayRate(e.target.value)}
            />
          </div>
        )}

        {/* Minimum Booking */}
        <div className="price-form-group">
          {bookingType === "hour" ? (
            <>
              <label>Minimum Booking Hours</label>
              <input
                type="number"
                placeholder="Enter minimum hours"
                value={minBooking}
                onChange={(e) => setMinBooking(e.target.value)}
              />
              <small className="field-note">
                Note: We recommend a minimum of 3 hours.
              </small>
            </>
          ) : (
            <>
              <label>Minimum Booking Days</label>
              <input
                type="number"
                placeholder="Enter minimum days"
                value={minBooking}
                onChange={(e) => setMinBooking(e.target.value)}
              />
              <small className="field-note">
                Note: We recommend a minimum of 1 day.
              </small>
            </>
          )}
        </div>
      </div>

      {/* Cleaning & Security */}
      <div className="form-row">
        <div className="price-form-group">
          <label>Cleaning Fees ($)</label>
          <input
            type="number"
            placeholder="Enter cleaning fee"
            value={cleaningFee}
            onChange={(e) => setCleaningFee(e.target.value)}
          />
        </div>
        <div className="price-form-group">
          <label>Security Deposit ($)</label>
          <input
            type="number"
            placeholder="Enter security deposit"
            value={securityDeposit}
            onChange={(e) => setSecurityDeposit(e.target.value)}
          />
          <small className="field-note">
            Note: Adjust this for longer bookings if necessary.
          </small>
        </div>
      </div>

      {/* Availability */}
      <h2 className="price-title">
        {bookingType === "hour"
          ? "Set Hourly Availability"
          : "Set Daily Availability"}
      </h2>
      <small className="info-note">
        Specify when your kitchen is available. Select a start/end time and a
        price. If it's the same each week, check "Every week".
      </small>

      <div className="availability-section">
        <div className="availability-row">
          <div className="price-form-group">
            <label>Start Time</label>
            <DatePicker
              selected={newStartDate}
              onChange={(date) => setNewStartDate(date)}
              showTimeSelect={bookingType === "hour"}
              dateFormat={bookingType === "hour" ? "Pp" : "P"}
              placeholderText="Select start"
            />
          </div>
          <div className="price-form-group">
            <label>End Time</label>
            <DatePicker
              selected={newEndDate}
              onChange={(date) => setNewEndDate(date)}
              showTimeSelect={bookingType === "hour"}
              dateFormat={bookingType === "hour" ? "Pp" : "P"}
              placeholderText="Select end"
            />
          </div>
          <div className="price-form-group">
            <label>Rate</label>
            <input
              type="number"
              placeholder="Enter price"
              value={newAvailabilityPrice}
              onChange={(e) => setNewAvailabilityPrice(e.target.value)}
            />
          </div>
          <div className="price-form-group recurring-group">
            <label>Recurring</label>
            <label className="switch">
              <input
                type="checkbox"
                checked={newRecurring}
                onChange={(e) => setNewRecurring(e.target.checked)}
              />
              <span className="slider"></span>
            </label>
          </div>
          <div className="entry-btn-container">
            <button
              type="button"
              className="add-entry-btn"
              onClick={handleAddAvailabilitySlot}
            >
              {editingSlotIndex !== null ? "Update" : "Add"}
            </button>
            {editingSlotIndex !== null && (
              <button
                type="button"
                className="add-entry-btn cancel-edit-btn"
                onClick={handleCancelEdit}
              >
                Cancel
              </button>
            )}
          </div>
        </div>

        {rateEntries.length > 0 && (
          <div className="availability-list">
            <h3>Availability Slots</h3>
            <ul>
              {rateEntries.map((slot, index) => (
                <li key={index}>
                  <span>
                    {new Date(slot.startDateTime).toLocaleString()} –{" "}
                    {new Date(slot.endDateTime).toLocaleString()} : $
                    {slot.price} {slot.recurring && <span>(Every week)</span>}
                  </span>
                  <div className="availability-actions">
                    <FaEdit
                      className="slot-edit-icon"
                      onClick={() => handleEditAvailabilitySlot(index)}
                    />
                    <FaTrash
                      className="slot-delete-icon"
                      onClick={() => handleRemoveAvailabilitySlot(index)}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Blocked Dates */}
      <h2 className="price-title">Blocked Dates</h2>
      <small className="info-note">
        Block specific dates to make the kitchen unavailable on those dates.
      </small>
      <div className="blocked-dates-container">
        <div className="blocked-date-picker">
          <DatePicker
            selected={newBlockedDate}
            onChange={(date) => setNewBlockedDate(date)}
            minDate={new Date()}
            placeholderText="Select a date to block"
          />
          <button
            className="add-entry-btn"
            type="button"
            onClick={handleAddBlockedDate}
          >
            Block Date
          </button>
        </div>
        {blockedDates.length > 0 && (
          <ul className="blocked-date-list">
            {blockedDates.map((dateStr, idx) => (
              <li key={idx}>
                {dateStr}{" "}
                <FaTimes
                  className="remove-blocked-date-icon"
                  onClick={() => handleRemoveBlockedDate(dateStr)}
                />
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button type="button" onClick={prevStep} className="prev-btn">
          Previous
        </button>
        <button type="submit" className="next-btn" disabled={loading}>
          {loading ? "Saving..." : editMode ? "Update" : "Next"}
        </button>
      </div>
    </form>
  );
};

export default KitchenPrice;
