import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./KitchenCard.css";

const KitchenCard = ({ kitchen, distance }) => {
  const { Title, City, DayRate, HourlyRate, BookingType, Images, KitchenID } =
    kitchen;

  // Use a fallback image if none are provided
  const imageArray =
    Images && Images.length > 0
      ? Images
      : [
          "https://airkitchenz-assets.s3.us-west-1.amazonaws.com/assets/KitchenComingSoon.png",
        ];

  const totalImages = imageArray.length;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrev = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? totalImages - 1 : prevIndex - 1
    );
  };

  const handleNext = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === totalImages - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Link to={`/kitchen/${KitchenID}`} className="kitchen-card-link">
      <div className="kitchen-card">
        <div className="image-container">
          <div
            className="image-slider"
            style={{
              transform: `translateX(-${currentImageIndex * 100}%)`,
            }}
          >
            {imageArray.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`${Title} ${index + 1}`}
                className="browse-kitchen-image"
                loading="lazy"
              />
            ))}
          </div>

          {/* Arrow buttons (only shown if there are multiple images) */}
          {totalImages > 1 && (
            <>
              <button className="img-arrow arrow-left" onClick={handlePrev}>
                &#10094;
              </button>
              <button className="img-arrow arrow-right" onClick={handleNext}>
                &#10095;
              </button>
            </>
          )}
        </div>
        <div className="kitchen-info">
          <ul>
            <li className="kitchen-title">{Title}</li>
            <li className="kitchen-city">{City}</li>
            <li className="kitchen-rate">
              {BookingType === "hour"
                ? `$${HourlyRate} per hour`
                : `$${DayRate} per day`}
            </li>
            {/* Display distance if available */}
            {distance && (
              <li className="kitchen-distance">{distance} miles away</li>
            )}
          </ul>
        </div>
      </div>
    </Link>
  );
};

export default KitchenCard;
