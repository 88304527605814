import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import RestaurantCard from "../../components/RestaurantCard/RestaurantCard";
import { BiCurrentLocation } from "react-icons/bi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from "../../config";
import { useRestaurant } from "../../context/RestaurantContext";
import "./Eatz4CaterCraze.css";

// Loads Google Maps script only once
const loadGoogleMapsScript = () => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById("google-maps-script");
    if (existingScript) {
      if (window.google && window.google.maps) {
        resolve();
      } else {
        const interval = setInterval(() => {
          if (window.google && window.google.maps) {
            clearInterval(interval);
            resolve();
          }
        }, 50);
      }
      return;
    }
    const script = document.createElement("script");
    script.id = "google-maps-script";
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.mapApiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.head.appendChild(script);
  });
};

const Eatz4CaterCraze = () => {
  const { fetchAllRestaurants, searchRestaurants } = useRestaurant();
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  // Filters: location, cuisine, minOrder, etc.
  const [filters, setFilters] = useState({
    location: "",
    latitude: null,
    longitude: null,
    cuisine: "",
    minOrder: null,
  });

  // Combined date/time picker state.
  const [selectedDateTime, setSelectedDateTime] = useState(null);

  // State to toggle advanced filters (cuisine & min order) on mobile.
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  // State to hide the hero paragraphs when scrolled down (mobile only)
  const [hideHeroParagraphs, setHideHeroParagraphs] = useState(false);

  // State to track if the location input is focused (for shrinking the hero area)
  const [isLocationFocused, setIsLocationFocused] = useState(false);

  // Ref for Google Places Autocomplete.
  const locationInputRef = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    loadGoogleMapsScript()
      .then(() => {
        setIsScriptLoaded(true);
        initializeAutocomplete();
      })
      .catch((err) => console.error("Error loading script:", err));
  }, []);

  // Set up scroll listener for mobile to hide hero paragraphs
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth < 768) {
        if (window.scrollY > 50) {
          setHideHeroParagraphs(true);
        } else {
          setHideHeroParagraphs(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const initializeAutocomplete = () => {
    if (locationInputRef.current && window.google && window.google.maps) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        locationInputRef.current,
        { types: ["geocode"] }
      );
      autocompleteRef.current.setFields(["geometry", "formatted_address"]);
      autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
    }
  };

  useEffect(() => {
    const loadRestaurants = async () => {
      setLoading(true);
      try {
        const allRestaurants = await fetchAllRestaurants();
        setRestaurants(allRestaurants);
      } catch (error) {
        console.error("Error loading restaurants:", error);
      } finally {
        setLoading(false);
      }
    };
    loadRestaurants();
  }, [fetchAllRestaurants]);

  // When the user selects an address from suggestions.
  const handlePlaceSelect = () => {
    if (!autocompleteRef.current) return;
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const address = place.formatted_address || "";
      const newFilters = {
        ...filters,
        location: address,
        latitude: lat,
        longitude: lng,
      };
      setFilters(newFilters);
      handleSearch(newFilters, selectedDateTime);
    }
  };

  // “Use My Location” button functionality.
  const handleGeoButtonClick = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser.");
      return;
    }
    setLoadingLocation(true);
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const lat = pos.coords.latitude;
        const lng = pos.coords.longitude;
        if (!window.google || !window.google.maps) {
          setFilters((prev) => ({
            ...prev,
            latitude: lat,
            longitude: lng,
          }));
          setLoadingLocation(false);
          return;
        }
        // Reverse geocode to get address.
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
          if (status === "OK" && results && results.length > 0) {
            const address = results[0].formatted_address;
            const updated = {
              ...filters,
              location: address,
              latitude: lat,
              longitude: lng,
            };
            setFilters(updated);
            handleSearch(updated, selectedDateTime);
          } else {
            console.error("Geocoder failed:", status);
            alert("Could not find an address for your location.");
          }
          setLoadingLocation(false);
        });
      },
      (err) => {
        console.error("Error getting location:", err);
        alert("Unable to retrieve location. Check your permissions.");
        setLoadingLocation(false);
      }
    );
  };

  // Handle Enter key in location input.
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (autocompleteRef.current) {
        handlePlaceSelect();
      } else {
        handleSearch(filters, selectedDateTime);
      }
    }
  };

  // Prepare date/time in the required format.
  const prepareDateTimeFilters = (dateObj) => {
    if (!dateObj) return { date: "", time: "" };
    const date = dateObj.toISOString().split("T")[0]; // YYYY-MM-DD
    const time = dateObj.toTimeString().split(" ")[0].slice(0, 5); // HH:MM (24-hour)
    return { date, time };
  };

  // Perform search using current filters.
  const handleSearch = async (currentFilters = filters, dateTime = selectedDateTime) => {
    setLoading(true);
    try {
      const { date, time } = prepareDateTimeFilters(dateTime);
      const searchFilters = { ...currentFilters, date, time };
      const result = await searchRestaurants(searchFilters);
      setRestaurants(result);
    } catch (error) {
      console.error("Error searching restaurants:", error);
      setRestaurants([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="eatz-page">
      <Navbar />
      <div className="eatz-content">
        {/* Hero Section */}
        <div className={`hero-section-unique ${hideHeroParagraphs || isLocationFocused ? "shrink" : ""}`}>
          <h1>Support Local Culinary Artisans &amp; Search for Restaurants</h1>
          {!(hideHeroParagraphs || isLocationFocused) && (
            <>
              <p>
              Connecting you with <strong>independently owned</strong> restaurants, small
              catering companies, and <strong>private</strong> chefs. Enjoy unique flavors
              while empowering small businesses.
            </p>
            <p>
              <strong>AirKitchenz</strong> is dedicated to promoting local culinary talent by
              exclusively featuring small, <strong>independently owned</strong> eateries and
              catering services. Unlike other platforms, we prioritize small businesses,
              ensuring they receive the <strong>visibility</strong> they deserve.
            </p>
            </>
          )}
        </div>

        {/* Search Bar */}
        <div className="eatz-search-bar">
          {/* Location Search Container */}
          <div className={`location-search-container ${isLocationFocused ? "focused" : ""}`}>
            <input
              type="text"
              placeholder="Search location..."
              ref={locationInputRef}
              value={filters.location}
              onFocus={() => {
                setIsLocationFocused(true);
                setHideHeroParagraphs(true);
              }}
              onBlur={() => {
                setIsLocationFocused(false);
                if (window.scrollY < 50) setHideHeroParagraphs(false);
              }}
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  location: e.target.value,
                  latitude: null,
                  longitude: null,
                }))
              }
              onKeyDown={handleKeyDown}
            />
            <button
              className="location-button"
              onClick={handleGeoButtonClick}
              disabled={loadingLocation}
            >
              {loadingLocation ? "Locating..." : <BiCurrentLocation />}
            </button>
          </div>

          {/* Desktop Advanced Filters */}
          <div className="advanced-filters desktop-only">
            <select
              value={filters.cuisine}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, cuisine: e.target.value }))
              }
            >
              <option value="">All Cuisines</option>
              <option value="Italian">Italian</option>
              <option value="Vegan">Vegan</option>
              <option value="Latin">Latin</option>
              <option value="New American">New American</option>
            </select>
            <input
              type="number"
              placeholder="Min Order"
              value={filters.minOrder || ""}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, minOrder: e.target.value }))
              }
            />
          </div>

          {/* DatePicker, Search Button & Mobile Filters Toggle */}
          <div className="date-search-container">
            <DatePicker
              className="date-picker-input"
              selected={selectedDateTime}
              onChange={(date) => setSelectedDateTime(date)}
              onFocus={() => setHideHeroParagraphs(true)}
              showTimeSelect
              timeIntervals={15}
              dateFormat="Pp"
              placeholderText="Select date and time"
            />
            <button
              className="filters-toggle mobile-only"
              onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
            >
              Filters
            </button>
            <button className="eatz-search-button" onClick={() => handleSearch()}>
              Search
            </button>
          </div>

          {/* Mobile Advanced Filters */}
          <div className={`advanced-filters mobile-only ${showAdvancedFilters ? "show" : ""}`}>
            <select
              value={filters.cuisine}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, cuisine: e.target.value }))
              }
            >
              <option value="">All Cuisines</option>
              <option value="Italian">Italian</option>
              <option value="Vegan">Vegan</option>
              <option value="Latin">Latin</option>
              <option value="New American">New American</option>
            </select>
            <input
              type="number"
              placeholder="Min Order"
              value={filters.minOrder || ""}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, minOrder: e.target.value }))
              }
            />
          </div>
        </div>

        {/* Restaurant List */}
        <div className="restaurant-list">
          {loading ? (
            <div className="loading">
              <div className="spinner-res" />
              <p>Loading Restaurants...</p>
            </div>
          ) : restaurants.length > 0 ? (
            restaurants.map((restaurant) => (
              <div key={restaurant.restaurantID}>
                <RestaurantCard restaurant={restaurant} />
              </div>
            ))
          ) : (
            <p className="no-results">No restaurants found</p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Eatz4CaterCraze;
