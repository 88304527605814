import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FaPlus, FaMinus, FaTrash } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./RestaurantPage.css";

// Commission rate (5% = 0.05)
const COMMISSION_RATE = 0.05;

const RestaurantPage = () => {
  const { name, restaurantID } = useParams();
  const decodedName = decodeURIComponent(name);

  const [restaurant, setRestaurant] = useState(null);
  const [foodItems, setFoodItems] = useState([]);
  const [cart, setCart] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("All");
  // Controls whether the cart modal is open or closed
  const [isCartOpen, setIsCartOpen] = useState(false);

  // Promo code states
  const [promoCode, setPromoCode] = useState("");
  const [promoMessage, setPromoMessage] = useState("");

  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        const response = await axios.get(`/api/restaurants/${restaurantID}`);
        setRestaurant(response.data);
      } catch (error) {
        console.error(
          "Error fetching restaurant data:",
          error.response ? error.response.data : error.message
        );
      }
    };

    const fetchFoodItems = async () => {
      try {
        const response = await axios.get(
          `/api/food-items/by-restaurant/${decodedName}`
        );
        setFoodItems(response.data);
      } catch (error) {
        console.error("Error fetching food items:", error);
      }
    };

    fetchRestaurantData();
    fetchFoodItems();
  }, [decodedName, restaurantID]);

  // Handle cart updates
  const handleAddToCart = (foodID) => {
    setCart((prevCart) => ({
      ...prevCart,
      [foodID]: (prevCart[foodID] || 0) + 1,
    }));
  };

  const handleRemoveFromCart = (foodID) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      if (updatedCart[foodID] > 1) {
        updatedCart[foodID] -= 1;
      } else {
        delete updatedCart[foodID];
      }
      return updatedCart;
    });
  };

  // Fully remove an item from the cart
  const handleDeleteFromCart = (foodID) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      delete updatedCart[foodID];
      return updatedCart;
    });
  };

  // Cart items + categories
  const cartItems = foodItems.filter((item) => cart[item.foodID]);
  const categories = ["All", ...new Set(foodItems.map((item) => item.category))];
  const filteredItems =
    selectedCategory === "All"
      ? foodItems
      : foodItems.filter((item) => item.category === selectedCategory);

  // Calculate totals
  const subtotal = cartItems.reduce(
    (total, item) => total + item.price * cart[item.foodID],
    0
  );
  const commission = subtotal * COMMISSION_RATE;
  let finalTotal = subtotal + commission;

  // For the cart badge - sum all item quantities
  const totalCartQuantity = cartItems.reduce(
    (sum, item) => sum + cart[item.foodID],
    0
  );

  // (Optional) handle promo code - basic example
  const handleApplyPromoCode = async () => {
    try {
      // Example: call your backend to verify the promo code
      // const response = await axios.post("/api/verify-promo", { code: promoCode });
      // if (response.data.valid) {
      //   setPromoMessage("Promo code applied!");
      //   ...maybe apply a discount...
      // } else {
      //   setPromoMessage("Invalid promo code.");
      // }
      // For now, let's just simulate:
      if (promoCode.trim().toLowerCase() === "discount10") {
        setPromoMessage("Promo code applied! 10% off.");
        finalTotal = finalTotal * 0.9; // sample discount
      } else {
        setPromoMessage("Promo code invalid or expired.");
      }
    } catch (error) {
      console.error("Error verifying promo code:", error);
      setPromoMessage("Failed to verify promo code.");
    }
  };

  // Checkout
  const handleCheckout = async () => {
    try {
      const response = await axios.post(
        "/api/stripe/create-food-checkout-session",
        {
          totalAmount: finalTotal,
          cartItems: cartItems.map((item) => ({
            id: item.foodID,
            name: item.name,
            quantity: cart[item.foodID],
            price: item.price,
          })),
          restaurantName: decodedName,
          restaurantID,
        }
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  return (
    <div className="restaurant-page">
      <Navbar />

      {/* Header Section */}
      <header className="restaurant-header">
        {restaurant && (
          <motion.div
            className="header-content"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="header-image">
              <img
                src={
                  restaurant.images?.[0] ||
                  "https://via.placeholder.com/800x400"
                }
                alt={restaurant.name}
                className="restaurant-page-image"
              />
            </div>
            <div className="header-details">
              <h1>{restaurant.name}</h1>
              <p className="location">{restaurant.location}</p>
              <p>Minimum Order: ${restaurant.min_order}</p>

              <div className="header-buttons">
                <button
                  className="view-menu-button"
                  onClick={() => window.open(restaurant.menu_pdf_url, "_blank")}
                >
                  View Menu
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </header>

      {/* Sticky Cart Button (always visible) */}
      <div className="cart-float-button">
        <button className="cart-button" onClick={() => setIsCartOpen(true)}>
          Cart
          {/* Show badge if there's at least 1 item */}
          {totalCartQuantity > 0 && (
            <span className="cart-badge">{totalCartQuantity}</span>
          )}
        </button>
      </div>

      {/* Main Content */}
      <main className="restaurant-content">
        {/* Category Bar */}
        <motion.nav
          className="category-bar"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {categories.map((category) => (
            <motion.button
              key={category}
              className={`category-item ${
                selectedCategory === category ? "active" : ""
              }`}
              onClick={() => setSelectedCategory(category)}
              whileHover={{
                scale: 1.1,
                backgroundColor: "#e63946",
                color: "#fff",
              }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <span className="label">{category}</span>
            </motion.button>
          ))}
        </motion.nav>

        {/* Food Items Section */}
        <motion.section
          className="food-items-section"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          <div className="food-items-grid">
            {filteredItems.map((item) => (
              <motion.div
                key={item.foodID}
                className="food-card"
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3)",
                }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="food-card-content">
                  <h3>{item.name}</h3>
                  <p className="category">{item.category}</p>
                  <p className="ingredients">{item.ingredients}</p>
                  <p className="price">${item.price}</p>
                </div>
                <div className="quantity-controls">
                  <button onClick={() => handleRemoveFromCart(item.foodID)}>
                    <FaMinus />
                  </button>
                  <span>{cart[item.foodID] || 0}</span>
                  <button onClick={() => handleAddToCart(item.foodID)}>
                    <FaPlus />
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.section>
      </main>

      {/* CART MODAL (Slide-in) */}
      <AnimatePresence>
        {isCartOpen && (
          <>
            {/* Backdrop */}
            <motion.div
              className="cart-backdrop"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={() => setIsCartOpen(false)}
            />
            {/* Slide-out panel */}
            <motion.div
              className="cart-modal"
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ duration: 0.3 }}
            >
              <div className="cart-modal-header">
                <h2>Your Cart</h2>
                <button
                  className="close-cart-button"
                  onClick={() => setIsCartOpen(false)}
                >
                  &times;
                </button>
              </div>

              {cartItems.length === 0 ? (
                <p className="empty-cart-msg">Your cart is empty</p>
              ) : (
                <>
                  <ul className="cart-items">
                    {cartItems.map((item) => (
                      <li key={item.foodID} className="cart-item">
                        <span className="item-name">{item.name}</span>

                        {/* Add/Subtract and Remove controls */}
                        <div className="cart-item-controls">
                          <button
                            className="icon-button"
                            onClick={() => handleRemoveFromCart(item.foodID)}
                          >
                            <FaMinus />
                          </button>
                          <span>{cart[item.foodID]}</span>
                          <button
                            className="icon-button"
                            onClick={() => handleAddToCart(item.foodID)}
                          >
                            <FaPlus />
                          </button>

                          <button
                            className="delete-button"
                            onClick={() => handleDeleteFromCart(item.foodID)}
                          >
                            <FaTrash />
                          </button>
                        </div>

                        <span className="item-total">
                          ${(item.price * cart[item.foodID]).toFixed(2)}
                        </span>
                      </li>
                    ))}
                  </ul>

                  {/* Promo Code Section */}
                  <div className="promo-code-area">
                    <input
                      type="text"
                      placeholder="Enter promo code"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                    <button
                      className="apply-promo-button"
                      onClick={handleApplyPromoCode}
                    >
                      Apply
                    </button>
                  </div>
                  {promoMessage && <p className="promo-message">{promoMessage}</p>}

                  <div className="cart-breakdown">
                    <div className="cart-row">
                      <span>Subtotal:</span>
                      <span>${subtotal.toFixed(2)}</span>
                    </div>
                    <div className="cart-row">
                      <span>Commission (5%):</span>
                      <span>${commission.toFixed(2)}</span>
                    </div>
                    <div className="cart-row total">
                      <span>Total:</span>
                      <span>${finalTotal.toFixed(2)}</span>
                    </div>
                  </div>

                  <motion.button
                    className="checkout-button"
                    onClick={handleCheckout}
                    whileHover={{ scale: 1.05, backgroundColor: "#d62839" }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    Checkout
                  </motion.button>
                </>
              )}
            </motion.div>
          </>
        )}
      </AnimatePresence>

      <Footer />
    </div>
  );
};

export default RestaurantPage;
