import React, { useEffect } from "react";
import { useFood } from "../../context/FoodContext";
import { Link } from "react-router-dom";
import "./MyFoodItems.css";

const MyFoodItems = () => {
  const { allFoodItems, setAllFoodItems, user, fetchFoodByUser } = useFood();

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.userID) {
        try {
          const items = await fetchFoodByUser(user.userID);
          setAllFoodItems(items);
          console.log(items);
        } catch (error) {
          console.error("Error fetching food items:", error);
        }
      }
    };
    fetchData();
  }, [setAllFoodItems, user]);

  const handleDelete = async (foodID) => {
    try {
      // Call delete API for food item
      await fetch(`/api/food/delete/${foodID}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      alert("Food item deleted successfully.");
      setAllFoodItems((prevItems) =>
        prevItems.filter((item) => item.foodID !== foodID)
      );
    } catch (error) {
      console.error("Error deleting food item:", error);
      alert("Failed to delete food item.");
    }
  };

  if (!user || !user.userID) {
    return (
      <p className="loading">
        Please log in or sign up to view your food items.
      </p>
    );
  }

  if (!allFoodItems || allFoodItems.length === 0) {
    return (
      <div className="my-food-items-container">
        <h1>MY FOOD ITEMS</h1>
        <p className="loading-food">No food items available.</p>
        <div className="bottom-button-container">
          <Link to="/profile/add-food-item" className="add-food-item-btn">
            + Add New Food Item
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="my-food-items-container">
      <div className="food-header">
        <h1>MY FOOD ITEMS</h1>
        <div className="bottom-button-container-1">
          <Link to="/profile/add-food-item" className="add-food-item-btn">
            + Add New Food Item
          </Link>
        </div>
      </div>

      <div className="food-items-list">
        {allFoodItems.map((item) => (
          <li key={item.foodID} className="food-item">
            <div className="food-item-details">
              <h3>{item.name}</h3>
              <p>Category: {item.category}</p>
              <p>Ingredients: {item.ingredients}</p>
              <p>Price: ${item.price}</p>
              <p>Size: {item.size}</p>
              <p>Serves: {item.serves} people</p>
            </div>
            <div className="food-item-actions">
              <button className="food-edit-btn">
                <Link to={`/profile/edit-food-item/${item.foodID}`}>Edit</Link>
              </button>
              <button
                className="food-delete-btn"
                onClick={() => handleDelete(item.foodID)}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </div>
    </div>
  );
};

export default MyFoodItems;
