import React, { useState, useEffect, useRef } from "react";
import { useRestaurant } from "../../context/RestaurantContext"; // Assuming you have a context for restaurants
import "./RestaurantLocation.css"; // Create a corresponding CSS file
import { FaMapMarkerAlt } from "react-icons/fa";

const loadGoogleMapsScript = (callback) => {
  if (!document.querySelector(`[src*="maps.googleapis.com"]`)) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&loading=async`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = () => callback();
  } else {
    callback();
  }
};

const RestaurantLocation = ({
  prevStep,
  nextStep,
  restaurantData,
  updateRestaurantData,
}) => {
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    loadGoogleMapsScript(() => {
      if (window.google && window.google.maps) {
        const map = new window.google.maps.Map(mapRef.current, {
          center: { lat: latitude, lng: longitude },
          zoom: 15,
        });

        const marker = new window.google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map,
          draggable: true,
          title: "Drag me!",
        });

        markerRef.current = marker;

        marker.addListener("dragend", (event) => {
          const lat = event.latLng.lat();
          const lng = event.latLng.lng();
          setLatitude(lat);
          setLongitude(lng);
          updateRestaurantData({ latitude: lat, longitude: lng });
        });

        const autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById("address-input"),
          { types: ["geocode"] }
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setLatitude(lat);
            setLongitude(lng);
            setAddress(place.formatted_address);
            updateRestaurantData({
              address: place.formatted_address,
              latitude: lat,
              longitude: lng,
            });
            map.setCenter({ lat, lng });
            marker.setPosition({ lat, lng });
          }
        });
      }
    });
  }, [latitude, longitude, updateRestaurantData]);

  return (
    <div className="restaurant-location-l">
      <h2>Restaurant Location</h2>
      <div className="form-group">
        <label htmlFor="address-input">Address</label>
        <div className="input-with-icon">
          <FaMapMarkerAlt className="input-icon" />
          <input
            type="text"
            id="address-input"
            placeholder="Enter restaurant address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
      </div>
      <div ref={mapRef} className="map-view"></div>
      <div className="restaurant-navigation-buttons">
        <button className="prev-btn" onClick={prevStep}>
          Back
        </button>
        <button className="next-btn" onClick={nextStep}>
          Next
        </button>
      </div>
    </div>
  );
};

export default RestaurantLocation;
