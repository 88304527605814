import React, { useState } from "react";
import axios from "axios";
import config from "../../config";
import "./ParseMenu.css";

const ParseMenu = ({ isOpen, onClose, restaurantOptions, onParsedMenuSubmit }) => {
  const [file, setFile] = useState(null);
  const [parsedMenu, setParsedMenu] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Prompt to be sent along with file
  const defaultPrompt = `
Extract data into a valid JSON array where each element is an object with keys:
  - name
  - category
  - price
  - size
  - serves (number of people it serves)
  - ingredients

Pass null if not understood.
Return ONLY the JSON array with no extra text.
`;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Helper function to update a single field in a menu item
  const handleItemChange = (index, field, value) => {
    setParsedMenu((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        [field]: value,
      };
      return updated;
    });
  };

  const handleUpload = async () => {
    if (!file) return;
    setLoading(true);
    setError("");

    const formData = new FormData();
    formData.append("menuFile", file);
    formData.append("prompt", defaultPrompt);

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/generate-items`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      // Server returns { menu: "...json string..." }
      let menuString = response.data.menu;
      console.log("Menu string from server:", menuString);

      if (typeof menuString !== "string") {
        setError("Invalid data from server: not a string");
        return;
      }

      try {
        // Parse the final JSON string
        const menuArray = JSON.parse(menuString);

        // Remove currency symbols from the price (e.g. $, £, etc.)
        // We'll allow digits, dot, and possibly a dash if there is a range like "79-149"
        const regex = /[^0-9.\-]/g;  // matches anything not digit, dot, or dash

        const cleanedArray = menuArray.map((item) => {
          let priceStr = item.price || "";
          if (typeof priceStr === "string") {
            // Remove any currency symbols or unwanted characters
            priceStr = priceStr.replace(regex, "");
          }
          return {
            ...item,
            price: priceStr,
          };
        });

        setParsedMenu(cleanedArray);
      } catch (parseErr) {
        console.error("Error parsing final JSON from server:", parseErr);
        setError("Invalid JSON format from server.");
        return;
      }
    } catch (err) {
      console.error("Error uploading or generating menu:", err);
      setError("Failed to generate menu. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (!selectedRestaurant) {
      setError("Please select a restaurant.");
      return;
    }
    // Pass these items (including any edits) back to the parent
    onParsedMenuSubmit(parsedMenu, selectedRestaurant);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Upload and Parse Menu</h2>
        <p className="instruction">
          We will limit the final list to 20 items. 
          Any incomplete final item is dropped server-side. PDFs only.
        </p>

        {error && <p className="error">{error}</p>}

        {/* File Upload */}
        <input 
          type="file" 
          accept="application/pdf" 
          onChange={handleFileChange}
        />

        <button onClick={handleUpload} disabled={loading || !file}>
          {loading ? "Processing..." : "Upload Menu"}
        </button>

        {/* Editable Grid of Items */}
        {parsedMenu.length > 0 && (
          <>
            <h3>Parsed Menu Items (up to 20)</h3>
            <div className="menu-table-container">
              <table className="menu-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Price</th>
                    <th>Size</th>
                    <th>Serves</th>
                    <th>Ingredients</th>
                  </tr>
                </thead>
                <tbody>
                  {parsedMenu.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          value={item.name || ""}
                          onChange={(e) => handleItemChange(index, "name", e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={item.category || ""}
                          onChange={(e) => handleItemChange(index, "category", e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={item.price || ""}
                          onChange={(e) => handleItemChange(index, "price", e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={item.size || ""}
                          onChange={(e) => handleItemChange(index, "size", e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={item.serves || ""}
                          onChange={(e) => handleItemChange(index, "serves", e.target.value)}
                        />
                      </td>
                      <td>
                        <textarea
                          rows="2"
                          value={item.ingredients || ""}
                          onChange={(e) => handleItemChange(index, "ingredients", e.target.value)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Restaurant Dropdown */}
            <div className="restaurant-select">
              <label>Select Restaurant:</label>
              <select
                value={selectedRestaurant}
                onChange={(e) => setSelectedRestaurant(e.target.value)}
              >
                <option value="">--Select--</option>
                {restaurantOptions.map((rest, idx) => (
                  <option key={idx} value={rest}>{rest}</option>
                ))}
              </select>
            </div>

            <button onClick={handleSubmit}>Submit Menu Items</button>
          </>
        )}

        {/* Close Button */}
        <button onClick={onClose} className="close-modal">
          Close
        </button>
      </div>
    </div>
  );
};

export default ParseMenu;
