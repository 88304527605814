import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { KitchenProvider } from "./context/KitchenContext";
import { BookingProvider } from "./context/BookingContext";
import Home from "./pages/Home/Home";
import AiBuilder from "./pages/AIBuilder/AIBuilder";
import BrowseKitchens from "./pages/BrowseKitchens/BrowseKitchens";
import Login from "./pages/Login/Login";
import Profile from "./pages/Profile/Profile";
import Kitchen from "./pages/Kitchen/Kitchen";
import AboutUs from "./pages/AboutUs/AboutUs";
import FAQs from "./pages/FAQs/FAQs";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import CareersPage from "./pages/CareersPage/CareersPage";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import KitchenWaitingList from "./pages/KitchenWaitingList/KitchenWaitingList";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetail from "./components/BlogDetails/BlogDetails";
import AddBlog from "./components/Admin/AddBlog";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Eatz4CaterCraze from "./pages/Eatz4CaterCraze/Eatz4CaterCraze";
import RestaurantPage from "./pages/RestaurantPage/RestaurantPage";
import Affiliate from "./pages/Affiliate/Affiliate";
import ContactUs from "./components/ContactUs/ContactUs";
import { RestaurantProvider } from "./context/RestaurantContext";

const AppRoutes = () => {
  return (
    <AuthProvider>
      <KitchenProvider>
        <BookingProvider>
          <RestaurantProvider>
            <Router
              future={{
                v7_startTransition: true,
                v7_relativeSplatPath: true,
              }}
            >
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<BrowseKitchens />} />
                <Route path="/admin" element={<AdminDashboard />} />
                <Route path="/aibuilder" element={<AiBuilder />} />
                <Route path="/eatz4CaterCraze" element={<Eatz4CaterCraze />} />
                <Route path="/browse" element={<BrowseKitchens />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile/*" element={<Profile />} />
                <Route path="/kitchen/:kitchenID" element={<Kitchen />} />
                <Route
                  path="/restaurant/:name/:restaurantID"
                  element={<RestaurantPage />}
                />
                <Route
                  path="/kitchen/:kitchenID/sucess"
                  element={<Kitchen />}
                />
                <Route path="/kitchen/:title" element={<Kitchen />} />
                <Route path="/features-services" element={<Home />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/faqs" element={<FAQs />} />
                <Route path="/affiliate" element={<Affiliate />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/careerspage" element={<CareersPage />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route
                  path="/kitchenwaitinglist"
                  element={<KitchenWaitingList />}
                />
                <Route path="/admin/add-blog" element={<AddBlog />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blogs/:id" element={<BlogDetail />} />
              </Routes>
            </Router>
          </RestaurantProvider>
        </BookingProvider>
      </KitchenProvider>
    </AuthProvider>
  );
};

export default AppRoutes;
