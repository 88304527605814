import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  FaRegFileAlt,
  FaDollarSign,
  FaImage,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { AuthContext } from "../../context/AuthContext";
import RestaurantDetails from "./RestaurantDetails";
import RestaurantPrice from "./RestaurantPrice";
import RestaurantImages from "./RestaurantImages";
import RestaurantLocation from "./RestaurantLocation";
import RestaurantHours from "./RestaurantHours";
import "./AddRestaurant.css";
import config from "../../config";

const AddRestaurant = ({ editMode }) => {
  const { restaurantID } = useParams();
  const { user } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [restaurantData, setRestaurantData] = useState({
    restaurantID: "",
    userID: "",
    name: "",
    location: "",
    link: "",
    hours_of_operation: { days: [], time: "" },
    min_order: "",
    delivery_fee: "",
    images: [],
    address: "",
    latitude: null,
    longitude: null,
    order_cutoff_time: "",
  });

  const renderIcons = () => (
    <ul className="icon-view-1">
      <li className={step === 1 ? "active" : ""} onClick={() => setStep(1)}>
        <FaRegFileAlt />
        {/* <p>Description</p> */}
      </li>
      <li className={step === 2 ? "active" : ""} onClick={() => setStep(2)}>
        <FaDollarSign />
        {/* <p>Price</p> */}
      </li>
      <li className={step === 3 ? "active" : ""} onClick={() => setStep(3)}>
        <FaImage />
        {/* <p>Images</p> */}
      </li>
      <li className={step === 4 ? "active" : ""} onClick={() => setStep(4)}>
        <FaMapMarkerAlt />
        {/* <p>Location</p> */}
      </li>
      <li className={step === 5 ? "active" : ""} onClick={() => setStep(5)}>
        <FiClock />
      </li>
    </ul>
  );

  useEffect(() => {
    if (user && user.userID) {
      setRestaurantData((prev) => ({ ...prev, userID: user.userID }));
    }
  }, [user]);

  useEffect(() => {
    if (editMode && restaurantID) {
      const fetchRestaurantData = async () => {
        try {
          const response = await axios.get(
            `${config.apiBaseUrl}/api/restaurants/${restaurantID}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          setRestaurantData(response.data);
        } catch (error) {
          console.error("Error fetching restaurant data:", error.message);
        }
      };
      fetchRestaurantData();
    }
  }, [editMode, restaurantID]);

  const updateRestaurantData = (newData) => {
    setRestaurantData((prevData) => ({ ...prevData, ...newData }));
  };

  const handleNextStep = async () => {
    try {
      if (!user || !user.userID) {
        alert("User is not authenticated. Please log in first.");
        return;
      }
      const dataToSend = { ...restaurantData, userID: user.userID };
      if (step === 1 && !restaurantData.restaurantID) {
        const response = await axios.post(
          `${config.apiBaseUrl}/api/restaurants/add`,
          dataToSend,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setRestaurantData((prev) => ({
          ...prev,
          restaurantID: response.data.id,
        }));
        alert("Restaurant created successfully!");
      } else {
        if (restaurantData.restaurantID) {
          await axios.put(
            `${config.apiBaseUrl}/api/restaurants/edit/${restaurantData.restaurantID}`,
            dataToSend,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          alert("Restaurant updated successfully!");
        }
      }
      setStep(step + 1);
    } catch (error) {
      console.error(
        "Error saving restaurant:",
        error.response?.data || error.message
      );
      alert("Failed to save restaurant data. Please try again.");
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleFinalSubmit = async () => {
    try {
      if (!user || !user.userID) {
        alert("User is not authenticated. Please log in first.");
        return;
      }
      if (!restaurantData.restaurantID) {
        alert("No restaurant found to update.");
        return;
      }
      const dataToSend = { ...restaurantData, userID: user.userID };
      await axios.put(
        `${config.apiBaseUrl}/api/restaurants/edit/${restaurantData.restaurantID}`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      alert("Restaurant fully updated!");
    } catch (error) {
      console.error(
        "Error finalizing restaurant:",
        error.response?.data || error.message
      );
      alert("Failed to finalize restaurant. Please try again.");
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <RestaurantDetails
            nextStep={handleNextStep}
            restaurantData={restaurantData}
            updateRestaurantData={updateRestaurantData}
          />
        );
      case 2:
        return (
          <RestaurantPrice
            prevStep={handlePrevStep}
            nextStep={handleNextStep}
            restaurantData={restaurantData}
            updateRestaurantData={updateRestaurantData}
          />
        );
      case 3:
        return (
          <RestaurantImages
            prevStep={handlePrevStep}
            nextStep={handleNextStep}
            restaurantData={restaurantData}
            updateRestaurantData={updateRestaurantData}
          />
        );
      case 4:
        return (
          <RestaurantLocation
            prevStep={handlePrevStep}
            nextStep={handleNextStep}
            restaurantData={restaurantData}
            updateRestaurantData={updateRestaurantData}
          />
        );
      case 5:
        return (
          <RestaurantHours
            prevStep={handlePrevStep}
            handleSubmit={handleFinalSubmit}
            restaurantData={restaurantData}
            updateRestaurantData={updateRestaurantData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="add-restaurant">
      <h1>{editMode ? "Edit Restaurant" : "Add New Restaurant"}</h1>
      <div className="progress-indicator">
        {window.innerWidth <= 768 ? (
          renderIcons()
        ) : (
          <ul>
            <li
              className={step === 1 ? "active" : ""}
              onClick={() => setStep(1)}
            >
              Description
            </li>
            <li
              className={step === 2 ? "active" : ""}
              onClick={() => setStep(2)}
            >
              Price
            </li>
            <li
              className={step === 3 ? "active" : ""}
              onClick={() => setStep(3)}
            >
              Images
            </li>
            <li
              className={step === 4 ? "active" : ""}
              onClick={() => setStep(4)}
            >
              Location
            </li>
            <li
              className={step === 5 ? "active" : ""}
              onClick={() => setStep(5)}
            >
              Hours
            </li>
          </ul>
        )}
      </div>
      <div className="step-content">{renderStep()}</div>
    </div>
  );
};

export default AddRestaurant;
