import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FaSignOutAlt } from "react-icons/fa";
import "./Navbar.css";
import UserProfile from "../../assets/images/user-profile.webp";

const Navbar = () => {
  const { user, logout } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="nav-bar">
      {/* Logo */}
      <div className="img">
        <a href="/">
          <img
            src={require("../../assets/images/header-logo.png")}
            alt="Logo"
            className="air-kitchenz-dark"
          />
        </a>
      </div>

      {/* Toggle Icon for Mobile */}
      <div className="navbar-toggle" onClick={toggleMenu}>
        {menuOpen ? (
          <AiOutlineClose className="menu-icon" />
        ) : (
          <AiOutlineMenu className="menu-icon" />
        )}
      </div>

      {/* Navbar Links */}
      <div className={`group navbar-links ${menuOpen ? "open" : ""}`}>
        <Link to="/profile/add-kitchen" className="feature-links">
          Submit your kitchen
        </Link>
        <Link to="/aibuilder" className="feature-links">
          AI-Chef tools
        </Link>
        <Link to="/kitchenwaitinglist" className="feature-links">
          Waitlist
        </Link>
        <Link to="/eatz4CaterCraze" className="feature-links">
          AirEatz
        </Link>
        {user ? (
          <div className="profile-icon">
            <a href="/profile" className="profile-link">
              <img
                src={user.profilePicture || UserProfile}
                alt={`${user.firstName} ${user.lastName}`}
                className="navbar-profile-picture"
              />
              <span className="greeting">Hi, {user.firstName}!</span>
            </a>
            <button
              onClick={logout}
              className="logout-button"
              aria-label="Logout"
            >
              <FaSignOutAlt />
            </button>
          </div>
        ) : (
          <div className="profile-icon">
            <a href="/login">Login</a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
