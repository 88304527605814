import React, { useState, useEffect, useRef } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { useKitchen } from "../../context/KitchenContext";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import KitchenCard from "../../components/KitchenCard/KitchenCard";
import MapComponent from "../../utils/MapComponent/MapComponent";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faMapMarkerAlt,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { BiCurrentLocation } from "react-icons/bi";
import { haversineDistance } from "../../utils/distance";
import config from "../../config";
import "./BrowseKitchens.css";
import { motion, AnimatePresence } from "framer-motion";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegSmile } from "react-icons/fa";

const loadGoogleMapsScript = (() => {
  let scriptLoadingPromise;
  return () => {
    if (window.google && window.google.maps) {
      return Promise.resolve();
    }
    if (scriptLoadingPromise) {
      return scriptLoadingPromise;
    }
    // Check if the script tag is already in the DOM
    const existingScript = document.querySelector(
      'script[src*="maps.googleapis.com/maps/api/js"]'
    );
    if (existingScript) {
      scriptLoadingPromise = new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          resolve();
        } else {
          existingScript.addEventListener("load", () => resolve());
          existingScript.addEventListener("error", (err) => reject(err));
        }
      });
      return scriptLoadingPromise;
    }
    // Otherwise, create a new script tag
    scriptLoadingPromise = new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${config.mapApiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = (error) => reject(error);
      document.head.appendChild(script);
    });
    return scriptLoadingPromise;
  };
})();

const BrowseKitchens = () => {
  const { fetchAllKitchens, searchKitchens } = useKitchen();

  // Initialize filters
  const [filters, setFilters] = useState({
    location: "",
    latitude: null,
    longitude: null,
    kitchenCategory: "",
    kitchenSize: "",
    checkinDate: null,
    checkoutDate: null,
    priceOrder: "asc",
    bookingType: "",
  });

  const [displayKitchens, setDisplayKitchens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingLocation, setLoadingLocation] = useState(false);

  const locationInputRef = useRef(null);
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const location = useLocation();

  // Modal state
  const [showFilters, setShowFilters] = useState(false);

  // State for sticky bar and its measured height
  const [isSticky, setIsSticky] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [searchBarHeight, setSearchBarHeight] = useState(0);
  const searchBarRef = useRef(null);

  // New: Dynamic sticky threshold based on viewport width.
  const [stickyThreshold, setStickyThreshold] = useState(
    window.innerWidth < 768 ? 100 : 240
  );

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  // Initialize smoothscroll polyfill
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  // Update stickyThreshold on window resize
  useEffect(() => {
    const updateThreshold = () => {
      if (window.innerWidth < 768) {
        setStickyThreshold(100);
      } else {
        setStickyThreshold(240);
      }
    };
    updateThreshold();
    window.addEventListener("resize", updateThreshold);
    return () => window.removeEventListener("resize", updateThreshold);
  }, []);

  // Throttle scroll events using requestAnimationFrame for smoother updates
  useEffect(() => {
    let ticking = false;
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const scrollPosition = window.scrollY;
          setIsSticky(scrollPosition > stickyThreshold);
          setIsScrolled(scrollPosition > stickyThreshold + 35);
          ticking = false;
        });
        ticking = true;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [stickyThreshold]);

  // Update the measured height of the search bar (for the spacer)
  useEffect(() => {
    const updateSearchBarHeight = () => {
      if (searchBarRef.current) {
        setSearchBarHeight(searchBarRef.current.getBoundingClientRect().height);
      }
    };
    updateSearchBarHeight();
    window.addEventListener("resize", updateSearchBarHeight);
    return () => window.removeEventListener("resize", updateSearchBarHeight);
  }, [isSticky]);

  useEffect(() => {
    // Load the Google Maps script
    loadGoogleMapsScript()
      .then(() => {
        setIsScriptLoaded(true);
        initializeAutocomplete();
      })
      .catch((error) => {
        console.error("Error loading Google Maps script:", error);
      });

    // Parse query parameters from URL
    const searchParams = new URLSearchParams(location.search);
    const params = {
      location: searchParams.get("location") || "",
      latitude: searchParams.get("latitude")
        ? parseFloat(searchParams.get("latitude"))
        : null,
      longitude: searchParams.get("longitude")
        ? parseFloat(searchParams.get("longitude"))
        : null,
      kitchenCategory: searchParams.get("kitchenCategory") || "",
      kitchenSize: searchParams.get("kitchenSize") || "",
      checkinDate: searchParams.get("checkinDate")
        ? new Date(searchParams.get("checkinDate"))
        : null,
      checkoutDate: searchParams.get("checkoutDate")
        ? new Date(searchParams.get("checkoutDate"))
        : null,
      priceOrder: searchParams.get("priceOrder") || "asc",
      bookingType: searchParams.get("bookingType") || "",
    };
    setFilters(params);

    // Decide whether to fetch all kitchens or perform a search based on filters
    const shouldFetchAll =
      params.latitude != null || params.kitchenCategory !== "";
    if (!shouldFetchAll) {
      loadAllKitchens();
    } else {
      handleSearch(params);
    }
  }, [location.search]);

  const initializeAutocomplete = () => {
    if (locationInputRef.current && window.google && window.google.maps) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        locationInputRef.current,
        { types: ["geocode"] }
      );
      autocompleteRef.current.setFields(["geometry", "formatted_address"]);
      autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
    }
  };

  // Function to load all kitchens
  const loadAllKitchens = async () => {
    setLoading(true);
    try {
      const allKitchens = await fetchAllKitchens();
      setDisplayKitchens(allKitchens);
    } catch (error) {
      console.error("Error loading all kitchens:", error);
      setDisplayKitchens([]);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle place selection from autocomplete
  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        const formattedAddress = place.formatted_address;
        setFilters((prevFilters) => ({
          ...prevFilters,
          location: formattedAddress,
          latitude: latitude,
          longitude: longitude,
        }));
        handleSearch({
          ...filters,
          location: formattedAddress,
          latitude: latitude,
          longitude: longitude,
        });
      }
    }
  };

  // Function to get user's current location
  const getUserLocation = () => {
    if (navigator.geolocation) {
      setLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLoadingLocation(false);
        },
        (error) => {
          console.error("Error getting location:", error);
          setLoadingLocation(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  };

  // Handlers for input events
  const handleLocationInputFocus = () => {
    getUserLocation();
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (autocompleteRef.current) {
        handlePlaceSelect();
      }
    }
  };

  // Handler for "Use My Location" button
  const handleGeoButtonClick = () => {
    if (navigator.geolocation) {
      setLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const geocoder = new window.google.maps.Geocoder();
          const latLng = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          };
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK") {
              if (results && results.length > 0) {
                const formattedAddress = results[0].formatted_address;
                const newFilters = {
                  ...filters,
                  location: formattedAddress,
                  latitude: latitude,
                  longitude: longitude,
                };
                setFilters(newFilters);
                handleSearch(newFilters);
              } else {
                console.error("No results found for the given coordinates.");
                alert("Unable to find a location for your coordinates.");
              }
            } else {
              console.error("Geocoder failed due to: " + status);
              alert("Failed to retrieve location. Please try again.");
            }
            setLoadingLocation(false);
          });
        },
        (error) => {
          console.error("Error getting location:", error);
          alert(
            "Unable to retrieve your location. Please check your permissions."
          );
          setLoadingLocation(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
      alert("Geolocation is not supported by your browser.");
    }
  };

  // Main search handler
  const handleSearch = async (searchFilters = filters) => {
    const {
      location,
      latitude,
      longitude,
      kitchenCategory,
      kitchenSize,
      checkinDate,
      checkoutDate,
      priceOrder,
      bookingType,
    } = searchFilters;

    const hasLocation = latitude !== null && longitude !== null;
    const hasKitchenCategory = kitchenCategory.trim() !== "";

    if (!hasLocation && !bookingType && !hasKitchenCategory) {
      loadAllKitchens();
      return;
    }

    setLoading(true);

    try {
      const cleanedFilters = {};
      if (hasLocation) {
        cleanedFilters.latitude = latitude;
        cleanedFilters.longitude = longitude;
      }
      if (hasKitchenCategory) {
        cleanedFilters.kitchenCategory = kitchenCategory;
      }
      if (kitchenSize) {
        cleanedFilters.kitchenSize = kitchenSize;
      }
      if (checkinDate) {
        cleanedFilters.checkinDate = checkinDate.toISOString();
      }
      if (checkoutDate) {
        cleanedFilters.checkoutDate = checkoutDate.toISOString();
      }
      if (priceOrder) {
        cleanedFilters.priceOrder = priceOrder;
      }
      if (bookingType) {
        cleanedFilters.bookingType = bookingType;
      }

      const filteredKitchens = (await searchKitchens(cleanedFilters)) || [];
      const kitchensWithDistance = filteredKitchens.map((kitchen) => {
        if (hasLocation) {
          const distance = haversineDistance(
            latitude,
            longitude,
            kitchen.Latitude,
            kitchen.Longitude
          );
          return { ...kitchen, distance: distance.toFixed(2) };
        }
        return { ...kitchen, distance: null };
      });
      setDisplayKitchens(kitchensWithDistance);
    } catch (error) {
      console.error("Error during search:", error);
      setDisplayKitchens([]);
    } finally {
      setLoading(false);
    }
  };

  // Modal handlers
  const handleApplyFilters = () => {
    handleSearch(filters);
    setShowFilters(false);
  };
  const handleOverlayClick = () => {
    setShowFilters(false);
  };
  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="browse-page">
      <Navbar />

      {/* Hero Section with Tagline and Search Bar */}
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">Look. Book. Cook.</h1>
          <p className="hero-subtitle">
            Turn restaurant kitchens’ downtime <br className="mobile-break" />
            into your prime time
          </p>
          <div
            ref={searchBarRef}
            className={`search-bar ${isSticky ? "sticky" : ""}`}
          >
            <motion.div
              className="browse-search-bar"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <div className="autocomplete-container">
                <button
                  className="geo-button"
                  onClick={handleGeoButtonClick}
                  disabled={loadingLocation}
                  aria-label="Use my current location"
                >
                  {loadingLocation ? (
                    <span className="spinner"></span>
                  ) : (
                    <BiCurrentLocation />
                  )}
                </button>
                <input
                  type="text"
                  ref={locationInputRef}
                  placeholder="Where do you want to cook, Chef?"
                  value={filters.location}
                  onChange={(e) =>
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      location: e.target.value,
                      latitude: null,
                      longitude: null,
                    }))
                  }
                  onFocus={handleLocationInputFocus}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleSearch(filters);
                }}
                className="browse-search-button"
                aria-label="Search Kitchens"
              >
                <FontAwesomeIcon icon={faSearch} />
              </Link>
            </motion.div>
            <div className={`buttons ${isScrolled ? "scrolled" : ""}`}>
              <div className="action-buttons">
                <button
                  className="filter-button"
                  onClick={() => setShowFilters(true)}
                  aria-label="Filter Options"
                  title="Filter Options"
                >
                  <FontAwesomeIcon icon={faSliders} /> Filter
                </button>
                <button
                  className="map-button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (mapRef.current) {
                      const navbar = document.querySelector(".navbar");
                      const navbarHeight = navbar ? navbar.offsetHeight : 0;
                      const elementRect =
                        mapRef.current.getBoundingClientRect();
                      const elementTop = elementRect.top + window.pageYOffset;
                      const elementCenter =
                        elementTop + mapRef.current.offsetHeight / 2;
                      const scrollToPosition =
                        elementCenter -
                        window.innerHeight / 2 +
                        navbarHeight / 2;
                      window.scrollTo({
                        top: scrollToPosition,
                        behavior: "smooth",
                      });
                    }
                  }}
                  aria-label="View on Map"
                  title="View on Map"
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} /> View Map
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Spacer to preserve layout when the search bar is sticky */}
      {isSticky && (
        <div
          className="sticky-placeholder"
          style={{ height: searchBarHeight }}
        />
      )}

      {/* Filter Modal with Framer Motion */}
      <AnimatePresence>
        {showFilters && (
          <motion.div
            className="filter-modal-overlay"
            onClick={handleOverlayClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="filter-modal-content"
              onClick={handleModalContentClick}
              initial={{ y: "100%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "100%", opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="filter-header">
                <h2>Refine Your Search</h2>
                <button
                  className="close-modal-button"
                  onClick={() => setShowFilters(false)}
                  aria-label="Close Filters"
                >
                  &times;
                </button>
              </div>

              <div className="filters">
                {/* Price Order */}
                <select
                  value={filters.priceOrder}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      priceOrder: e.target.value,
                    }))
                  }
                >
                  <option value="asc">Price: Low to High</option>
                  <option value="desc">Price: High to Low</option>
                </select>

                {/* Kitchen Category */}
                <select
                  value={filters.kitchenCategory}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      kitchenCategory: e.target.value,
                    }))
                  }
                >
                  <option value="">Kitchen Category</option>
                  <option value="commercial">Commercial Kitchen</option>
                  <option value="restaurant">Restaurant Kitchen</option>
                  <option value="ghost">Ghost Kitchen</option>
                  <option value="bakery">Bakery Kitchen</option>
                  <option value="private">Private Kitchen</option>
                  <option value="all">All Purpose Kitchen</option>
                </select>

                {/* Booking Type */}
                <select
                  value={filters.bookingType}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      bookingType: e.target.value,
                    }))
                  }
                >
                  <option value="">Booking Type</option>
                  <option value="hour">Hourly</option>
                  <option value="day">Daily</option>
                </select>

                {/* Date Fields (no time) */}
                <div className="date-time-filters">
                  <label>Check-in Date</label>
                  <DatePicker
                    selected={filters.checkinDate}
                    onChange={(date) =>
                      setFilters((prev) => ({ ...prev, checkinDate: date }))
                    }
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select Check-In Date"
                    className="date-picker-input"
                  />
                  <label>Check-out Date</label>
                  <DatePicker
                    selected={filters.checkoutDate}
                    onChange={(date) =>
                      setFilters((prev) => ({ ...prev, checkoutDate: date }))
                    }
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select Check-Out Date"
                    className="date-picker-input"
                  />
                </div>
              </div>

              {/* Apply Filters Button */}
              <div className="modal-actions">
                <button
                  className="apply-filters-button"
                  onClick={handleApplyFilters}
                >
                  Apply Filters
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Kitchen List */}
      <section className="kitchen-section">
        <div className="kitchen-list">
          {loading ? (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p className="loading-text">
                Looking for the perfect kitchen for you...
              </p>
            </div>
          ) : displayKitchens.length > 0 ? (
            displayKitchens.map((kitchen) => (
              <div className="kitchen-card-container" key={kitchen.KitchenID}>
                <KitchenCard kitchen={kitchen} distance={kitchen.distance} />
              </div>
            ))
          ) : (
            <div className="no-results">
              <FaRegSmile size={50} color="#e63946" />
              <p>No kitchens found matching your criteria.</p>
            </div>
          )}
        </div>
      </section>

      {/* Map Component */}
      {isScriptLoaded && (
        <section className="map-section" ref={mapRef}>
          <div className="map-container">
            <MapComponent kitchens={displayKitchens} />
          </div>
        </section>
      )}

      <Footer />
    </div>
  );
};

export default BrowseKitchens;
